"use strict";

var _interopRequireDefault = require("C:/Users/Admin/Documents/028web_mall/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.MallVoucherStatusQueryTrans = exports.MallVoucherStatusQuery = exports.MallVoucherDiscountType = exports.MallVoucherApplyTypeTrans = exports.MallVoucherApplyType = void 0;
var _defineProperty2 = _interopRequireDefault(require("C:/Users/Admin/Documents/028web_mall/node_modules/@babel/runtime/helpers/defineProperty.js"));
var MallVoucherStatusQuery;
(function (MallVoucherStatusQuery) {
  MallVoucherStatusQuery["Showing"] = "SHOWING";
  MallVoucherStatusQuery["ComingSoon"] = "COMING_SOON";
  MallVoucherStatusQuery["End"] = "END";
})(MallVoucherStatusQuery || (exports.MallVoucherStatusQuery = MallVoucherStatusQuery = {}));
var MallVoucherStatusQueryTrans = exports.MallVoucherStatusQueryTrans = (0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)({}, MallVoucherStatusQuery.ComingSoon, 'Sắp diễn ra'), MallVoucherStatusQuery.Showing, 'Đang diễn ra'), MallVoucherStatusQuery.End, 'Đã kết thúc');
var MallVoucherDiscountType;
(function (MallVoucherDiscountType) {
  MallVoucherDiscountType["Price"] = "PRICE";
  MallVoucherDiscountType["Percent"] = "PERCENT";
})(MallVoucherDiscountType || (exports.MallVoucherDiscountType = MallVoucherDiscountType = {}));
var MallVoucherApplyType;
(function (MallVoucherApplyType) {
  MallVoucherApplyType["All"] = "ALL";
  MallVoucherApplyType["Product"] = "PRODUCT";
  MallVoucherApplyType["ShipFee"] = "SHIP_FEE"; //Phí vận chuyển 
})(MallVoucherApplyType || (exports.MallVoucherApplyType = MallVoucherApplyType = {}));
var MallVoucherApplyTypeTrans = exports.MallVoucherApplyTypeTrans = (0, _defineProperty2.default)((0, _defineProperty2.default)({}, MallVoucherApplyType.All, 'Voucher toàn shop'), MallVoucherApplyType.Product, 'Voucher sản phẩm');