"use strict";

var _interopRequireDefault = require("C:/Users/Admin/Documents/028web_mall/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.MallCategoryTypeTrans = exports.MallCategoryType = void 0;
var _defineProperty2 = _interopRequireDefault(require("C:/Users/Admin/Documents/028web_mall/node_modules/@babel/runtime/helpers/defineProperty.js"));
var MallCategoryType;
(function (MallCategoryType) {
  MallCategoryType["Product"] = "PRODUCT";
  MallCategoryType["EVoucher"] = "EVOUCHER";
})(MallCategoryType || (exports.MallCategoryType = MallCategoryType = {}));
var MallCategoryTypeTrans = exports.MallCategoryTypeTrans = (0, _defineProperty2.default)((0, _defineProperty2.default)({}, MallCategoryType.Product, {
  value: MallCategoryType.Product,
  label: "Sản phẩm"
}), MallCategoryType.EVoucher, {
  value: MallCategoryType.EVoucher,
  label: "E-voucher"
});