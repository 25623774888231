"use strict";

var _interopRequireDefault = require("C:/Users/Admin/Documents/028web_mall/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.authApi = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
var authApi = exports.authApi = {
  register: function register(data) {
    return (0, _request.default)({
      url: "/auth/register",
      method: "post",
      data: data
    });
  },
  clearNotification: function clearNotification() {
    return (0, _request.default)({
      url: "/auth/clear/web-notification",
      method: "delete"
    });
  },
  createPin: function createPin(data) {
    return (0, _request.default)({
      url: "/auth/pin",
      method: "post",
      data: data
    });
  },
  validatePin: function validatePin(data) {
    return (0, _request.default)({
      url: "/auth/pin/validate",
      method: "post",
      data: data
    });
  },
  updatePin: function updatePin(data) {
    return (0, _request.default)({
      url: "/auth/pin",
      method: "patch",
      data: data
    });
  }
};