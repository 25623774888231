var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "upload-control" } },
    [
      _c(
        "el-upload",
        {
          style:
            "width: " +
            _vm.computeWidth +
            ";\n  height: " +
            _vm.computeHeight +
            ";margin:0 auto",
          attrs: {
            name: "file",
            drag: "",
            "before-upload": _vm.beforeUpload,
            action: _vm.$baseUrl + _vm.urlUpload,
            "on-success": _vm.handleSuccess,
            "show-file-list": false,
            headers: { token: _vm.token },
            "on-progress": _vm.onProgress,
            "on-error": _vm.onError,
            disabled: _vm.disabled,
          },
        },
        [
          _vm.isProgressing
            ? _c("el-progress", {
                attrs: { type: "circle", percentage: _vm.percentUpload },
              })
            : _vm._e(),
          !_vm.isProgressing
            ? [
                _vm.pathImage
                  ? _c("img", {
                      staticClass: "image",
                      staticStyle: {
                        "object-fit": "contain",
                        width: "100%",
                        height: "100%",
                      },
                      attrs: { src: _vm.imageUrl, alt: "" },
                    })
                  : [
                      _vm.$slots.icon
                        ? _c("div", [_vm._t("icon")], 2)
                        : [
                            _c("i", { staticClass: "el-icon-upload" }),
                            _c("div", { staticClass: "el-upload__text" }, [
                              _vm._v(" Kéo thả vào đây hoặc "),
                              _c("em", [_vm._v("click vào để upload")]),
                            ]),
                          ],
                    ],
              ]
            : _vm._e(),
          _vm.disabled
            ? _c("span", { staticClass: "upload-actions" }, [
                _c(
                  "span",
                  {
                    staticClass: "upload-actions-delete",
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        return _vm.emitRemove($event)
                      },
                    },
                  },
                  [_c("i", { staticClass: "el-icon-delete" })]
                ),
              ])
            : _vm._e(),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }