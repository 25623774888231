var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        visible: _vm.visible,
        title: "Nội dung khiếu nại",
        width: "700px",
        top: "30px",
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: { model: _vm.form, rules: _vm.rules, "label-position": "top" },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "Ảnh bằng chứng", prop: "images" } },
            [
              _c("MultipleImageS3", {
                attrs: { fileList: _vm.fileList },
                on: {
                  "upload:success": _vm.handleUploadSuccess,
                  "remove:success": _vm.handleRemove,
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "Video", prop: "video" } },
            [
              _c(
                "UploadVideoS3",
                {
                  attrs: {
                    width: 300,
                    height: 150,
                    videoUrl: _vm.videoUrl.url,
                    disabled: _vm.videoUrl.url != "",
                  },
                  on: {
                    "upload:success": function (path) {
                      return _vm.setPathVideo(path, _vm.index)
                    },
                    delete: function () {
                      return _vm.removeVideo(_vm.item)
                    },
                  },
                },
                [
                  _c("template", { slot: "icon" }, [
                    _c("i", { staticClass: "el-icon-plus" }),
                  ]),
                ],
                2
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { prop: "content", label: "Nội dung khiếu nại" } },
            [
              _c("el-input", {
                attrs: { type: "textarea" },
                model: {
                  value: _vm.form.content,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "content", $$v)
                  },
                  expression: "form.content",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "span",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  _vm.visible = false
                },
              },
            },
            [_vm._v("Đóng")]
          ),
          _c(
            "el-button",
            {
              attrs: {
                slot: "reference",
                type: "primary",
                loading: _vm.loading,
              },
              on: { click: _vm.handleComplain },
              slot: "reference",
            },
            [_vm._v("Gửi khiếu nại")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }