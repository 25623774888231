var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      staticClass: "book-qrcode-modal",
      attrs: {
        visible: _vm.visible,
        width: "600px ",
        center: "",
        "append-to-body": "",
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
      },
      scopedSlots: _vm._u([
        {
          key: "title",
          fn: function () {
            return undefined
          },
          proxy: true,
        },
      ]),
    },
    [
      _c(
        "section",
        { staticStyle: { "text-align": "center" } },
        [
          _c(
            "span",
            {
              staticStyle: {
                "font-weight": "600",
                color: "#000",
                "font-size": "14px",
                "margin-top": "14px",
                display: "inline-block",
                "margin-bottom": "14px",
              },
            },
            [_vm._v(" Mã: " + _vm._s(_vm.code || "--") + " ")]
          ),
          _c("QrcodeVue", { attrs: { value: _vm.code, size: 200 } }),
        ],
        1
      ),
      _c("span", { attrs: { slot: "footer" }, slot: "footer" }, [
        _c(
          "div",
          { staticStyle: { flex: "1" } },
          [
            _c(
              "el-button",
              {
                staticStyle: {
                  "padding-left": "36px",
                  "padding-right": "36px",
                  "border-radius": "26px",
                },
                attrs: { type: "primary", size: "default" },
                on: {
                  click: function ($event) {
                    _vm.visible = false
                  },
                },
              },
              [_vm._v("OK")]
            ),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }