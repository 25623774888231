var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "product-attr-list" },
    [
      _c(
        "el-table",
        {
          attrs: {
            data: _vm.data,
            border: "",
            fit: "",
            "span-method": _vm.objectSpanMethod,
          },
        },
        [
          _c("el-table-column", {
            attrs: {
              label: _vm.productAttr1.name,
              prop: "name1",
              align: "center",
            },
          }),
          _vm.visibleAttr2
            ? _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: _vm.productAttr2.name,
                  prop: "name2",
                },
              })
            : _vm._e(),
          _c("el-table-column", {
            attrs: { label: "Giá gốc" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c("el-input", {
                      directives: [
                        {
                          name: "mask",
                          rawName: "v-mask",
                          value: {
                            alias: "decimal",
                            groupSeparator: ".",
                            rightAlign: false,
                            autoGroup: true,
                            repeat: 20,
                            autoUnmask: true,
                            allowMinus: false,
                          },
                          expression:
                            "{\n            alias: 'decimal',\n            groupSeparator: '.',\n            rightAlign: false,\n            autoGroup: true,\n            repeat: 20,\n            autoUnmask: true,\n            allowMinus: false,\n          }",
                        },
                      ],
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "prepend",
                            fn: function () {
                              return [_vm._v("đ")]
                            },
                            proxy: true,
                          },
                        ],
                        null,
                        true
                      ),
                      model: {
                        value: row.price,
                        callback: function ($$v) {
                          _vm.$set(row, "price", $$v)
                        },
                        expression: "row.price",
                      },
                    }),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Giá khuyến mãi" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c("el-input", {
                      directives: [
                        {
                          name: "mask",
                          rawName: "v-mask",
                          value: {
                            alias: "decimal",
                            groupSeparator: ".",
                            rightAlign: false,
                            autoGroup: true,
                            repeat: 20,
                            autoUnmask: true,
                            allowMinus: false,
                          },
                          expression:
                            "{\n            alias: 'decimal',\n            groupSeparator: '.',\n            rightAlign: false,\n            autoGroup: true,\n            repeat: 20,\n            autoUnmask: true,\n            allowMinus: false,\n          }",
                        },
                      ],
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "prepend",
                            fn: function () {
                              return [_vm._v("đ")]
                            },
                            proxy: true,
                          },
                        ],
                        null,
                        true
                      ),
                      model: {
                        value: row.finalPrice,
                        callback: function ($$v) {
                          _vm.$set(row, "finalPrice", $$v)
                        },
                        expression: "row.finalPrice",
                      },
                    }),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Kho hàng" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c("el-input", {
                      directives: [
                        {
                          name: "mask",
                          rawName: "v-mask",
                          value: {
                            alias: "decimal",
                            groupSeparator: ".",
                            rightAlign: false,
                            autoGroup: true,
                            repeat: 20,
                            autoUnmask: true,
                            allowMinus: false,
                          },
                          expression:
                            "{\n            alias: 'decimal',\n            groupSeparator: '.',\n            rightAlign: false,\n            autoGroup: true,\n            repeat: 20,\n            autoUnmask: true,\n            allowMinus: false,\n          }",
                        },
                      ],
                      model: {
                        value: row.remain,
                        callback: function ($$v) {
                          _vm.$set(row, "remain", $$v)
                        },
                        expression: "row.remain",
                      },
                    }),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "SKU phân loại" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c("el-input", {
                      model: {
                        value: row.sku,
                        callback: function ($$v) {
                          _vm.$set(row, "sku", $$v)
                        },
                        expression: "row.sku",
                      },
                    }),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }