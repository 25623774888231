var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "Quét mã QR",
        visible: _vm.visible,
        width: "700px",
        top: "30px",
        "append-to-body": "",
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
        close: _vm.handleClose,
      },
    },
    [
      _vm.visible
        ? _c("QrCodeScanner", {
            ref: "QrCodeScanner",
            attrs: { type: _vm.typeCamera },
            on: { "scan:ok": _vm.handleScanOk },
          })
        : _vm._e(),
      _c("span", { attrs: { slot: "footer" }, slot: "footer" }, [
        _c(
          "div",
          { staticStyle: { flex: "1", "text-align": "center" } },
          [
            _c(
              "el-button",
              {
                staticStyle: {
                  "padding-left": "36px",
                  "padding-right": "36px",
                  "border-radius": "26px",
                },
                attrs: { type: "primary", size: "default" },
                on: {
                  click: function ($event) {
                    return _vm.handleChangeCamera()
                  },
                },
              },
              [_vm._v("Chuyển camera")]
            ),
            _c(
              "el-button",
              {
                staticStyle: {
                  "padding-left": "36px",
                  "padding-right": "36px",
                  "border-radius": "26px",
                },
                attrs: { type: "primary", size: "default" },
                on: {
                  click: function ($event) {
                    _vm.visible = false
                  },
                },
              },
              [_vm._v("Đóng")]
            ),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }