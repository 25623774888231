var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "Chi tiết e-voucher",
        visible: _vm.visible,
        width: "500px",
        top: "30px",
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
      },
    },
    [
      _c("div", [
        _c("img", {
          staticStyle: { "border-radius": "8px" },
          attrs: {
            width: "200",
            src: _vm.$url.image(_vm.form.imageUrl),
            alt: "",
          },
        }),
        _c(
          "ul",
          {
            staticClass: "voucher-info-list",
            staticStyle: { "padding-left": "8px" },
          },
          [
            _c("li", { staticClass: "list-item" }, [
              _c("label", { attrs: { for: "" } }, [_vm._v("Tên e-voucher:")]),
              _c("span", [_vm._v(" " + _vm._s(_vm.form.name))]),
            ]),
            _c("li", { staticClass: "list-item" }, [
              _c("label", { attrs: { for: "" } }, [_vm._v("Giá bán:")]),
              _c("span", [
                _vm._v(" " + _vm._s(_vm.$formatNumberVN(_vm.form.price)) + "đ"),
              ]),
            ]),
            _vm.form.conditionMinPrice
              ? _c("li", { staticClass: "list-item" }, [
                  _c("label", { attrs: { for: "" } }, [
                    _vm._v("Giá trị đơn hàng tối thiểu:"),
                  ]),
                  _c("span", [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$formatNumberVN(_vm.form.conditionMinPrice)
                        ) +
                        "đ"
                    ),
                  ]),
                ])
              : _vm._e(),
            _c(
              "li",
              { staticClass: "list-item" },
              [
                _c("label", { attrs: { for: "" } }, [_vm._v("Giảm giá:")]),
                _vm.form.discountType == _vm.EVoucherDiscountType.Percent
                  ? [_vm._v(" " + _vm._s(_vm.form.discountValue) + "% ")]
                  : [
                      _vm._v(
                        " " +
                          _vm._s(_vm.$formatNumberVN(_vm.form.discountValue)) +
                          "đ "
                      ),
                    ],
              ],
              2
            ),
            _vm.form.discountMaxValue &&
            _vm.form.discountType == _vm.EVoucherDiscountType.Percent
              ? _c("li", { staticClass: "list-item" }, [
                  _c("label", { attrs: { for: "" } }, [
                    _vm._v("Mức giảm tối đa:"),
                  ]),
                  _c("span", [
                    _vm._v(
                      " " +
                        _vm._s(_vm.$formatNumberVN(_vm.form.discountMaxValue)) +
                        "đ"
                    ),
                  ]),
                ])
              : _vm._e(),
            _c("li", { staticClass: "list-item" }, [
              _c("label", { attrs: { for: "" } }, [
                _vm._v("Thời gian áp dụng:"),
              ]),
              _c("span", [
                _vm._v(
                  " " +
                    _vm._s(_vm.$formatDate(_vm.form.startAt)) +
                    " - " +
                    _vm._s(_vm.$formatDate(_vm.form.endAt))
                ),
              ]),
            ]),
          ]
        ),
      ]),
      _c(
        "span",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  _vm.visible = false
                },
              },
            },
            [_vm._v("Huỷ")]
          ),
          _c(
            "el-button",
            {
              attrs: { type: "primary", loading: _vm.loading },
              on: {
                click: function ($event) {
                  return _vm.handleUse()
                },
              },
            },
            [_vm._v("Sử dụng")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }