"use strict";

var _interopRequireDefault = require("C:/Users/Admin/Documents/028web_mall/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.storeNewsApi = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
var storeNewsApi = exports.storeNewsApi = {
  findAll: function findAll(params) {
    return (0, _request.default)({
      url: '/storeNews',
      params: params
    });
  }
};