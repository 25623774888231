var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "basic-info" } },
    [
      _c(
        "el-card",
        { attrs: { shadow: "always", "body-style": { padding: "20px" } } },
        [
          _c("div", { attrs: { slot: "header" }, slot: "header" }, [
            _c("h2", [_vm._v("Thông tin cơ bản")]),
          ]),
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                model: _vm.form,
                rules: _vm.rules,
                "label-width": "170px",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "Hình ảnh sản phẩm", prop: "medias" } },
                [
                  _c(
                    "div",
                    { staticStyle: { display: "flex", "flex-wrap": "wrap" } },
                    [
                      _c(
                        "div",
                        { staticStyle: { "margin-right": "8px" } },
                        [
                          _c(
                            "SingleImageS3",
                            {
                              attrs: {
                                width: 100,
                                height: 100,
                                pathImage: _vm.form.mallProduct.thumbnail,
                                prefixFile: _vm.PrefixImage.Mall,
                              },
                              on: { "upload:success": _vm.setFrontImage },
                            },
                            [
                              _c("template", { slot: "icon" }, [
                                _c("i", { staticClass: "el-icon-plus" }),
                              ]),
                            ],
                            2
                          ),
                          _c("span", { staticClass: "label-image" }, [
                            _vm._v("* Ảnh bìa"),
                          ]),
                        ],
                        1
                      ),
                      _vm._l(
                        _vm.form.medias.filter(function (e) {
                          return e.type == _vm.MediaType.Image
                        }),
                        function (item, index) {
                          return _c(
                            "div",
                            {
                              key: index,
                              staticStyle: { "margin-right": "8px" },
                            },
                            [
                              _c(
                                "SingleImageS3",
                                {
                                  attrs: {
                                    width: 100,
                                    height: 100,
                                    pathImage: item.url,
                                    prefixFile: _vm.PrefixImage.Mall,
                                    disabled: item.url != "",
                                  },
                                  on: {
                                    "upload:success": function (path) {
                                      return _vm.setPathImage(
                                        path,
                                        index,
                                        _vm.MediaType.Image
                                      )
                                    },
                                    remove: function () {
                                      return _vm.removeImage(item)
                                    },
                                  },
                                },
                                [
                                  _c("template", { slot: "icon" }, [
                                    _c("i", { staticClass: "el-icon-plus" }),
                                  ]),
                                ],
                                2
                              ),
                              _c("span", { staticClass: "label-image" }, [
                                _vm._v("Hình ảnh " + _vm._s(index + 1)),
                              ]),
                            ],
                            1
                          )
                        }
                      ),
                    ],
                    2
                  ),
                ]
              ),
              _c(
                "el-form-item",
                { attrs: { label: "Video sản phẩm", prop: "medias" } },
                [
                  _c(
                    "div",
                    { staticStyle: { display: "flex", "flex-wrap": "wrap" } },
                    _vm._l(
                      _vm.form.medias.filter(function (e) {
                        return e.type == _vm.MediaType.Video
                      }),
                      function (item, index) {
                        return _c(
                          "div",
                          {
                            key: index,
                            staticStyle: { "margin-right": "8px" },
                          },
                          [
                            _c(
                              "UploadVideoS3",
                              {
                                attrs: {
                                  width: 100,
                                  height: 100,
                                  videoUrl: item.url,
                                  prefixFile: _vm.PrefixImage.Mall,
                                  disabled: item.url != "",
                                },
                                on: {
                                  "upload:success": function (path) {
                                    return _vm.setPathImage(
                                      path,
                                      index,
                                      _vm.MediaType.Video
                                    )
                                  },
                                  delete: function () {
                                    return _vm.removeImage(item)
                                  },
                                },
                              },
                              [
                                _c("template", { slot: "icon" }, [
                                  _c("i", { staticClass: "el-icon-plus" }),
                                ]),
                              ],
                              2
                            ),
                            _c("span", { staticClass: "label-image" }, [
                              _vm._v("Video " + _vm._s(index + 1)),
                            ]),
                          ],
                          1
                        )
                      }
                    ),
                    0
                  ),
                ]
              ),
              _c(
                "el-form-item",
                { attrs: { label: "Tên sản phẩm", prop: "mallProduct.name" } },
                [
                  _c("el-input", {
                    attrs: {
                      size: "medium",
                      type: "text",
                      maxlength: "120",
                      "show-word-limit": "",
                    },
                    model: {
                      value: _vm.form.mallProduct.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.form.mallProduct, "name", $$v)
                      },
                      expression: "form.mallProduct.name",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "Mô tả sản phẩm",
                    prop: "mallProduct.description",
                  },
                },
                [
                  _c("el-input", {
                    attrs: { type: "textarea", rows: 6 },
                    model: {
                      value: _vm.form.mallProduct.description,
                      callback: function ($$v) {
                        _vm.$set(_vm.form.mallProduct, "description", $$v)
                      },
                      expression: "form.mallProduct.description",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "Danh mục", prop: "mallCategoryId" } },
                [
                  _c("el-cascader", {
                    staticStyle: { width: "100%" },
                    attrs: {
                      options: _vm.mallCategoryTree,
                      clearable: "",
                      props: _vm.propCustom,
                    },
                    model: {
                      value: _vm.form.mallCategoryId,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "mallCategoryId", $$v)
                      },
                      expression: "form.mallCategoryId",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticClass: "font-small",
                  staticStyle: { "font-size": "12px !important" },
                  attrs: {
                    label: "Đánh dấu là chưa có hàng",
                    prop: "isComingSoon",
                  },
                },
                [
                  _c("el-switch", {
                    attrs: { "active-value": true, "inactive-value": false },
                    model: {
                      value: _vm.form.mallProduct.isComingSoon,
                      callback: function ($$v) {
                        _vm.$set(_vm.form.mallProduct, "isComingSoon", $$v)
                      },
                      expression: "form.mallProduct.isComingSoon",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }