"use strict";

var _interopRequireDefault = require("C:/Users/Admin/Documents/028web_mall/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.flashSaleStrategyApi = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
var flashSaleStrategyApi = exports.flashSaleStrategyApi = {
  findAll: function findAll(params) {
    return (0, _request.default)({
      url: "/flashSaleStrategy",
      params: params
    });
  },
  findOne: function findOne(id) {
    return (0, _request.default)({
      url: "/flashSaleStrategy/".concat(id)
    });
  },
  create: function create(data) {
    return (0, _request.default)({
      url: "/flashSaleStrategy",
      data: data,
      method: 'post'
    });
  },
  update: function update(id, data) {
    return (0, _request.default)({
      url: "/flashSaleStrategy/".concat(id),
      method: 'put',
      data: data
    });
  },
  delete: function _delete(id) {
    return (0, _request.default)({
      url: "/flashSaleStrategy/".concat(id),
      method: 'delete'
    });
  },
  enable: function enable(data) {
    return (0, _request.default)({
      url: "/flashSaleStrategy/mallProduct/enable",
      data: data,
      method: 'post'
    });
  },
  disable: function disable(data) {
    return (0, _request.default)({
      url: "/flashSaleStrategy/mallProduct/disable",
      data: data,
      method: 'post'
    });
  }
};