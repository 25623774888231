var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "Chọn sản phẩm",
        visible: _vm.visible,
        width: "1200px",
        top: "30px",
        "append-to-body": "",
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
      },
    },
    [
      !_vm.isView
        ? _c(
            "div",
            { staticClass: "filter-container" },
            [
              _c(
                "div",
                { staticClass: "filter-item" },
                [
                  _c("label", { attrs: { for: "" } }, [_vm._v("Tìm kiếm")]),
                  _c("el-input", {
                    staticStyle: { width: "300px" },
                    attrs: {
                      "prefix-icon": "el-icon-search",
                      placeholder: "Tìm tên sản phẩm",
                    },
                    nativeOn: {
                      keyup: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        ) {
                          return null
                        }
                        return _vm.fetchData($event)
                      },
                    },
                    model: {
                      value: _vm.listQuery.search,
                      callback: function ($$v) {
                        _vm.$set(_vm.listQuery, "search", $$v)
                      },
                      expression: "listQuery.search",
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "filter-item" },
                [
                  _c("label", { attrs: { for: "" } }, [_vm._v("Cấp 1")]),
                  _c(
                    "el-select",
                    {
                      on: { change: _vm.handleChangeLv1 },
                      model: {
                        value: _vm.listQuery.lv1Id,
                        callback: function ($$v) {
                          _vm.$set(_vm.listQuery, "lv1Id", $$v)
                        },
                        expression: "listQuery.lv1Id",
                      },
                    },
                    [
                      _c("el-option", {
                        attrs: { label: "Tất cả", value: null },
                      }),
                      _vm._l(_vm.categoryLv1, function (item) {
                        return _c("el-option", {
                          key: item.id,
                          attrs: { label: "" + item.name, value: item.id },
                        })
                      }),
                    ],
                    2
                  ),
                ],
                1
              ),
              _vm.listQuery.lv1Id
                ? _c(
                    "div",
                    { staticClass: "filter-item" },
                    [
                      _c("label", { attrs: { for: "" } }, [_vm._v("Cấp 2")]),
                      _c(
                        "el-select",
                        {
                          on: { change: _vm.handleChangeLv2 },
                          model: {
                            value: _vm.listQuery.lv2Id,
                            callback: function ($$v) {
                              _vm.$set(_vm.listQuery, "lv2Id", $$v)
                            },
                            expression: "listQuery.lv2Id",
                          },
                        },
                        [
                          _c("el-option", {
                            attrs: { label: "Tất cả", value: null },
                          }),
                          _vm._l(_vm.categoryLv2, function (item) {
                            return _c("el-option", {
                              key: item.id,
                              attrs: { label: "" + item.name, value: item.id },
                            })
                          }),
                        ],
                        2
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "el-button",
                {
                  staticClass: "filter-item",
                  staticStyle: { transform: "translateY(10px)" },
                  attrs: { type: "primary", icon: "el-icon-edit" },
                  on: { click: _vm.fetchData },
                },
                [_vm._v("Tìm kiếm")]
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          ref: "multipleTable",
          attrs: { data: _vm.data, border: "", fit: "" },
          on: { select: _vm.handleSelect },
        },
        [
          !_vm.isView
            ? _c("el-table-column", {
                attrs: { type: "selection", width: "50" },
              })
            : _vm._e(),
          _c("el-table-column", {
            attrs: { label: "Tên sản phẩm", prop: "" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c("img", {
                      staticStyle: { "border-radius": "8px" },
                      attrs: {
                        width: "150",
                        src: _vm.$url.image(row.thumbnail),
                        alt: "",
                      },
                    }),
                    _c("div", { staticStyle: { "font-weight": "bold" } }, [
                      _vm._v(" " + _vm._s(row.name) + " "),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { sortable: "", label: "Thông tin" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c("div", [
                      _c("b", [_vm._v("Danh mục:")]),
                      _vm._v(" " + _vm._s(row.category && row.category.name)),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { sortable: "", label: "Đã bán" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _vm._v(
                      " " + _vm._s(_vm.$formatNumberVN(row.quantitySold)) + " "
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { sortable: "", label: "Giá" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c("div", [
                      _c("b", [_vm._v("Giá bán:")]),
                      _vm._v(
                        " " +
                          _vm._s(
                            row.pricingType == _vm.MallProductPricingType.Single
                              ? _vm.$formatNumberVN(row.finalPrice)
                              : _vm.renderPrice(row)
                          ) +
                          "đ "
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { sortable: "", label: "Kho hàng" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [_vm._v(" " + _vm._s(_vm.renderRemain(row)) + " ")]
                },
              },
            ]),
          }),
        ],
        1
      ),
      !_vm.isView
        ? _c("pagination", {
            attrs: {
              total: _vm.total,
              page: _vm.listQuery.page,
              limit: _vm.listQuery.limit,
            },
            on: {
              "update:page": function ($event) {
                return _vm.$set(_vm.listQuery, "page", $event)
              },
              "update:limit": function ($event) {
                return _vm.$set(_vm.listQuery, "limit", $event)
              },
              pagination: _vm.fetchData,
            },
          })
        : _vm._e(),
      _c(
        "span",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  _vm.visible = false
                },
              },
            },
            [_vm._v("Đóng")]
          ),
          _c(
            "el-button",
            {
              attrs: { type: "primary", loading: _vm.loading },
              on: {
                click: function ($event) {
                  return _vm.onSubmitOk()
                },
              },
            },
            [_vm._v("Đồng ý")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }