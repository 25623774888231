"use strict";

var _interopRequireDefault = require("C:/Users/Admin/Documents/028web_mall/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.array.includes.js");
var _Account = _interopRequireDefault(require("./components/Account"));
var _UserCard = _interopRequireDefault(require("./components/UserCard"));
var _Info = _interopRequireDefault(require("./components/Info"));
var _Pin = _interopRequireDefault(require("./components/Pin"));
var _bankInfo = _interopRequireDefault(require("./components/bank-info.vue"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: "Profile",
  components: {
    Account: _Account.default,
    UserCard: _UserCard.default,
    Info: _Info.default,
    BankInfo: _bankInfo.default,
    Pin: _Pin.default
  },
  data: function data() {
    return {
      activeTab: "info"
    };
  },
  methods: {},
  mounted: function mounted() {
    var activeTabName = ["info", "account", "pin"];
    var page = this.$route.query.page;
    if (activeTabName.includes(page)) {
      this.activeTab = page;
    }
  }
};