var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "basic-product" } },
    [
      _c(
        "el-card",
        { attrs: { shadow: "always", "body-style": { padding: "20px" } } },
        [
          _c("div", { attrs: { slot: "header" }, slot: "header" }, [
            _c("h2", [_vm._v("Sản phẩm tham gia Flash Sale của Shop")]),
          ]),
          _c("div", { staticClass: "select-product" }, [
            _vm.form.selection.length
              ? _c("span", [
                  _c("label", [
                    _vm._v(
                      _vm._s(_vm.$formatNumberVN(_vm.form.selection.length))
                    ),
                  ]),
                  _vm._v(" Sản phẩm được chọn"),
                ])
              : _vm._e(),
            _c(
              "div",
              [
                _vm.status == "create" ||
                _vm.statusVoucher == _vm.MallVoucherStatusQuery.ComingSoon
                  ? _c(
                      "el-button",
                      {
                        attrs: {
                          plain: "",
                          type: "primary",
                          icon: "el-icon-plus",
                          disabled: !_vm.form.flashSaleStrategy.startAt,
                        },
                        on: { click: _vm.handleAddProduct },
                      },
                      [_vm._v("Thêm sản phẩm")]
                    )
                  : _vm._e(),
                !_vm.form.flashSaleStrategy.startAt
                  ? _c("div", [
                      _c("small", [_vm._v("Vui lòng lựa chọn khung giờ")]),
                    ])
                  : _vm._e(),
              ],
              1
            ),
          ]),
          _vm.form.selection.length
            ? _c("div", { staticClass: "product-list" }, [
                _c("div", { staticClass: "header-card" }, [
                  _c("label", { attrs: { for: "" } }, [
                    _vm._v("Phân loại hàng"),
                  ]),
                  _c("label", { attrs: { for: "" } }, [_vm._v("Giá gốc")]),
                  _c("label", { attrs: { for: "" } }, [_vm._v("Giá đã giảm")]),
                  _c("label", { attrs: { for: "" } }, [
                    _vm._v("SL sản phẩm khuyến mãi"),
                  ]),
                  _c("label", { attrs: { for: "" } }, [_vm._v("Kho hàng")]),
                  _c("label", { attrs: { for: "" } }, [_vm._v("Bật/tắt")]),
                  _c("label", { attrs: { for: "" } }, [_vm._v("Hành động")]),
                ]),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.loading,
                        expression: "loading",
                      },
                    ],
                    staticClass: "body-card",
                  },
                  [
                    _vm._l(_vm.form.selection, function (item, index) {
                      return _c(
                        "div",
                        { key: index, staticClass: "table-item" },
                        [
                          _c("div", { staticClass: "product-item" }, [
                            _c("div", { staticClass: "price" }, [
                              _c("div", { staticClass: "space-between" }, [
                                _c("img", {
                                  staticStyle: {
                                    "border-radius": "8px",
                                    "object-fit": "contain",
                                  },
                                  attrs: {
                                    width: "40",
                                    src: _vm.$url.image(item.thumbnail),
                                    alt: "",
                                  },
                                }),
                                _c(
                                  "div",
                                  {
                                    staticClass: "name-product",
                                    staticStyle: { "margin-left": "10px" },
                                  },
                                  [_vm._v(" " + _vm._s(item.name) + " ")]
                                ),
                              ]),
                            ]),
                            _c("div"),
                            _c("div"),
                            _c("div"),
                            _c("div"),
                            _c("div"),
                            _c(
                              "div",
                              [
                                _vm.status == "create" ||
                                _vm.statusVoucher ==
                                  _vm.MallVoucherStatusQuery.ComingSoon
                                  ? _c("el-button", {
                                      attrs: {
                                        type: "danger",
                                        icon: "el-icon-delete",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.handleDelete(item)
                                        },
                                      },
                                    })
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ]),
                          _vm._l(
                            item.pricingDetails,
                            function (itemPricing, indexPricing) {
                              return _c(
                                "div",
                                { key: indexPricing, staticClass: "summary" },
                                [
                                  _c("div", [
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(itemPricing.attrValue1.value)
                                      ),
                                    ]),
                                  ]),
                                  _c("div", [
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$formatNumberVN(itemPricing.price)
                                        ) + "đ"
                                      ),
                                    ]),
                                  ]),
                                  _c(
                                    "div",
                                    [
                                      _c("el-input", {
                                        directives: [
                                          {
                                            name: "mask",
                                            rawName: "v-mask",
                                            value: {
                                              alias: "decimal",
                                              groupSeparator: ".",
                                              rightAlign: false,
                                              autoGroup: true,
                                              repeat: 20,
                                              autoUnmask: true,
                                              allowMinus: false,
                                            },
                                            expression:
                                              "{alias: 'decimal' ,groupSeparator: '.' ,rightAlign: false, autoGroup: true, repeat: 20, autoUnmask: true, allowMinus: false}",
                                          },
                                        ],
                                        model: {
                                          value: itemPricing.promotionPrice,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              itemPricing,
                                              "promotionPrice",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "itemPricing.promotionPrice",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    [
                                      _c("el-input", {
                                        directives: [
                                          {
                                            name: "mask",
                                            rawName: "v-mask",
                                            value: {
                                              alias: "decimal",
                                              groupSeparator: ".",
                                              rightAlign: false,
                                              autoGroup: true,
                                              repeat: 20,
                                              autoUnmask: true,
                                              allowMinus: false,
                                              max: itemPricing.remain,
                                            },
                                            expression:
                                              "{alias: 'decimal' ,groupSeparator: '.' ,rightAlign: false, autoGroup: true, repeat: 20, autoUnmask: true, allowMinus: false, max:itemPricing.remain}",
                                          },
                                        ],
                                        model: {
                                          value: itemPricing.stock,
                                          callback: function ($$v) {
                                            _vm.$set(itemPricing, "stock", $$v)
                                          },
                                          expression: "itemPricing.stock",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c("div", [
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$formatNumberVN(
                                            itemPricing.remain
                                          )
                                        )
                                      ),
                                    ]),
                                  ]),
                                  _c(
                                    "div",
                                    [
                                      _c("el-switch", {
                                        attrs: {
                                          value: itemPricing.isEnable,
                                          disabled:
                                            _vm.status != "create" &&
                                            _vm.statusVoucher !=
                                              _vm.MallVoucherStatusQuery
                                                .ComingSoon,
                                        },
                                        on: {
                                          change: function ($event) {
                                            return _vm.handleEnable(
                                              itemPricing,
                                              item
                                            )
                                          },
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]
                              )
                            }
                          ),
                        ],
                        2
                      )
                    }),
                  ],
                  2
                ),
              ])
            : _vm._e(),
        ]
      ),
      _c("product-table-modal", {
        ref: "ProductTableModal",
        on: { "submit:ok": _vm.handleSelectProducts },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }