var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "transport" } },
    [
      _c(
        "el-card",
        { attrs: { shadow: "always", "body-style": { padding: "20px" } } },
        [
          _c("div", { attrs: { slot: "header" }, slot: "header" }, [
            _c("h2", [_vm._v("Vận chuyển")]),
          ]),
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                model: _vm.form,
                rules: _vm.rules,
                "label-width": "170px",
              },
            },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "Cân nặng (Sau khi đóng gói)",
                    prop: "mallProduct.weight",
                  },
                },
                [
                  _c(
                    "el-input",
                    {
                      directives: [
                        {
                          name: "mask",
                          rawName: "v-mask",
                          value: {
                            alias: "decimal",
                            groupSeparator: ".",
                            rightAlign: false,
                            autoGroup: true,
                            repeat: 20,
                            autoUnmask: true,
                            allowMinus: false,
                          },
                          expression:
                            "{alias: 'decimal' ,groupSeparator: '.' ,rightAlign: false, autoGroup: true, repeat: 20, autoUnmask: true, allowMinus: false}",
                        },
                      ],
                      attrs: { size: "medium" },
                      model: {
                        value: _vm.form.mallProduct.weight,
                        callback: function ($$v) {
                          _vm.$set(_vm.form.mallProduct, "weight", $$v)
                        },
                        expression: "form.mallProduct.weight",
                      },
                    },
                    [_c("template", { slot: "append" }, [_vm._v("gr")])],
                    2
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label:
                      "Kích thước đóng gói (Phí vận chuyển thực tế sẽ thay đổi nếu bạn nhập sai kích thước)",
                    prop: "mallProduct.description",
                  },
                },
                [
                  _c(
                    "el-row",
                    { attrs: { gutter: 20, type: "flex" } },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-input",
                            {
                              directives: [
                                {
                                  name: "mask",
                                  rawName: "v-mask",
                                  value: {
                                    alias: "decimal",
                                    groupSeparator: ".",
                                    rightAlign: false,
                                    autoGroup: true,
                                    repeat: 20,
                                    autoUnmask: true,
                                    allowMinus: false,
                                  },
                                  expression:
                                    "{alias: 'decimal' ,groupSeparator: '.' ,rightAlign: false, autoGroup: true, repeat: 20, autoUnmask: true, allowMinus: false}",
                                },
                              ],
                              attrs: { placeholder: "Rộng", size: "medium" },
                              model: {
                                value: _vm.form.mallProduct.width,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form.mallProduct, "width", $$v)
                                },
                                expression: "form.mallProduct.width",
                              },
                            },
                            [
                              _c("template", { slot: "append" }, [
                                _vm._v("cm"),
                              ]),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-input",
                            {
                              directives: [
                                {
                                  name: "mask",
                                  rawName: "v-mask",
                                  value: {
                                    alias: "decimal",
                                    groupSeparator: ".",
                                    rightAlign: false,
                                    autoGroup: true,
                                    repeat: 20,
                                    autoUnmask: true,
                                    allowMinus: false,
                                  },
                                  expression:
                                    "{alias: 'decimal' ,groupSeparator: '.' ,rightAlign: false, autoGroup: true, repeat: 20, autoUnmask: true, allowMinus: false}",
                                },
                              ],
                              attrs: { placeholder: "Dài", size: "medium" },
                              model: {
                                value: _vm.form.mallProduct.length,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form.mallProduct, "length", $$v)
                                },
                                expression: "form.mallProduct.length",
                              },
                            },
                            [
                              _c("template", { slot: "append" }, [
                                _vm._v("cm"),
                              ]),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-input",
                            {
                              directives: [
                                {
                                  name: "mask",
                                  rawName: "v-mask",
                                  value: {
                                    alias: "decimal",
                                    groupSeparator: ".",
                                    rightAlign: false,
                                    autoGroup: true,
                                    repeat: 20,
                                    autoUnmask: true,
                                    allowMinus: false,
                                  },
                                  expression:
                                    "{alias: 'decimal' ,groupSeparator: '.' ,rightAlign: false, autoGroup: true, repeat: 20, autoUnmask: true, allowMinus: false}",
                                },
                              ],
                              attrs: { placeholder: "Cao", size: "medium" },
                              model: {
                                value: _vm.form.mallProduct.height,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form.mallProduct, "height", $$v)
                                },
                                expression: "form.mallProduct.height",
                              },
                            },
                            [
                              _c("template", { slot: "append" }, [
                                _vm._v("cm"),
                              ]),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }