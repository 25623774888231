var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "div",
        { staticClass: "filter-container" },
        [
          _c(
            "div",
            { staticClass: "filter-item" },
            [
              _c("label", { attrs: { for: "" } }, [_vm._v("Ngày")]),
              _c("el-date-picker", {
                attrs: { type: "daterange", format: "dd/MM/yyyy" },
                on: { change: _vm.fetchData },
                model: {
                  value: _vm.date,
                  callback: function ($$v) {
                    _vm.date = $$v
                  },
                  expression: "date",
                },
              }),
            ],
            1
          ),
          _c("address-filter", {
            attrs: {
              query: _vm.query,
              visibleWard: false,
              visibleDistrict: false,
            },
            on: { "change:address": _vm.fetchData },
          }),
          _c(
            "div",
            { staticClass: "filter-item" },
            [
              _c(
                "el-button",
                {
                  attrs: {
                    type: "primary",
                    icon: "el-icon-search",
                    size: "small",
                  },
                  on: { click: _vm.fetchData },
                },
                [_vm._v("Tìm")]
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "filter-item" },
            [
              _c(
                "el-button",
                {
                  attrs: {
                    type: "primary",
                    icon: "el-icon-plus",
                    size: "small",
                  },
                  on: { click: _vm.handleCreate },
                },
                [_vm._v("Thêm banner")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          attrs: { data: _vm.data, border: "", fit: "" },
        },
        [
          _c("el-table-column", {
            attrs: { width: "200px", label: "Banner", prop: "" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c("img", {
                      staticStyle: { "border-radius": "5px" },
                      attrs: {
                        src: _vm.$url.image(row.image),
                        width: "150",
                        alt: "",
                      },
                    }),
                    _c("br"),
                    _c("label", { attrs: { for: "" } }, [
                      _vm._v(_vm._s(row.title)),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Sản phẩm" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    row.mallProducts.length
                      ? _c("div", [
                          _c("span", [
                            _vm._v(
                              " " +
                                _vm._s(row.mallProducts.length) +
                                " sản phẩm "
                            ),
                          ]),
                        ])
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Loại banner" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c("span", [
                      _vm._v(
                        " " + _vm._s(_vm.MallBannerTypeTrans[row.type]) + " "
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Thời gian", prop: "startAt", width: "170" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c("div", [
                      _c("label", { attrs: { for: "" } }, [
                        _vm._v("Ngày bắt đầu:"),
                      ]),
                      _c("span", [
                        _vm._v(_vm._s(_vm.$formatDate(row.startAt))),
                      ]),
                    ]),
                    _c("div", [
                      _c("label", { attrs: { for: "" } }, [
                        _vm._v("Ngày kết thúc:"),
                      ]),
                      _c("span", [_vm._v(_vm._s(_vm.$formatDate(row.endAt)))]),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Ngày tạo", width: "150" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _vm._v(
                      " " + _vm._s(_vm.$formatDateTime(row.dateCreated)) + " "
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Hành động", width: "180" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary", size: "mini" },
                        on: {
                          click: function ($event) {
                            return _vm.handleUpdate(row)
                          },
                        },
                      },
                      [_vm._v("Cập nhật")]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "danger", size: "mini" },
                        on: {
                          click: function ($event) {
                            return _vm.handleDelete(row)
                          },
                        },
                      },
                      [_vm._v("Xóa")]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c("pagination", {
        attrs: {
          total: _vm.total,
          page: _vm.query.page,
          limit: _vm.query.limit,
        },
        on: {
          "update:page": function ($event) {
            return _vm.$set(_vm.query, "page", $event)
          },
          "update:limit": function ($event) {
            return _vm.$set(_vm.query, "limit", $event)
          },
          pagination: _vm.fetchData,
        },
      }),
      _c("banner-dialog", {
        ref: "BannerDialog",
        on: { "submit:ok": _vm.fetchData },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }