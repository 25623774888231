"use strict";

var _interopRequireDefault = require("C:/Users/Admin/Documents/028web_mall/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
var _vue = _interopRequireDefault(require("vue"));
var _inputmask = _interopRequireDefault(require("inputmask"));
_vue.default.directive('mask', {
  bind: function bind(el, binding) {
    var findGroup = el.classList.contains("el-input-group--prepend");
    if (findGroup) {
      if (el.getElementsByTagName('INPUT')[1]) {
        (0, _inputmask.default)(binding.value).mask(el.getElementsByTagName('INPUT')[1]);
      } else {
        (0, _inputmask.default)(binding.value).mask(el.getElementsByTagName('INPUT')[0]);
      }
    } else {
      (0, _inputmask.default)(binding.value).mask(el.getElementsByTagName('INPUT')[0]);
    }
  }
});
// v-mask="{alias: 'decimal' ,groupSeparator: '.' ,rightAlign: false, autoGroup: true, repeat: 20, autoUnmask: true, allowMinus: false}"