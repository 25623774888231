var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "other-info" } },
    [
      _c(
        "el-card",
        { attrs: { shadow: "always", "body-style": { padding: "20px" } } },
        [
          _c("div", { attrs: { slot: "header" }, slot: "header" }, [
            _c("h2", [_vm._v("Thông tin khác")]),
          ]),
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                model: _vm.form,
                rules: _vm.rules,
                "label-width": "170px",
              },
            },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "Hàng đặt trước",
                    prop: "mallProduct.isMustPreOrder",
                  },
                },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.form.mallProduct.isMustPreOrder,
                        callback: function ($$v) {
                          _vm.$set(_vm.form.mallProduct, "isMustPreOrder", $$v)
                        },
                        expression: "form.mallProduct.isMustPreOrder",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: false } }, [
                        _vm._v("Không"),
                      ]),
                      _c("el-radio", { attrs: { label: true } }, [
                        _vm._v("Đồng ý"),
                      ]),
                    ],
                    1
                  ),
                  _c("small", { staticStyle: { display: "block" } }, [
                    _vm._v(
                      "Tôi sẽ gửi hàng trong 2 ngày (không bao gồm các ngày nghỉ lễ, Tết và những ngày đơn vị vận chuyển không làm việc)"
                    ),
                  ]),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "Sản phẩm nổi bật",
                    prop: "mallProduct.isHighlight",
                  },
                },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.form.mallProduct.isHighlight,
                        callback: function ($$v) {
                          _vm.$set(_vm.form.mallProduct, "isHighlight", $$v)
                        },
                        expression: "form.mallProduct.isHighlight",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: false } }, [
                        _vm._v("Không"),
                      ]),
                      _c("el-radio", { attrs: { label: true } }, [
                        _vm._v("Có"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "Sản phẩm độc lạ",
                    prop: "mallProduct.isSpecial",
                  },
                },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.form.mallProduct.isSpecial,
                        callback: function ($$v) {
                          _vm.$set(_vm.form.mallProduct, "isSpecial", $$v)
                        },
                        expression: "form.mallProduct.isSpecial",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: false } }, [
                        _vm._v("Không"),
                      ]),
                      _c("el-radio", { attrs: { label: true } }, [
                        _vm._v("Có"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "Tình trạng",
                    prop: "mallProduct.usedStatus",
                  },
                },
                [
                  _c(
                    "el-select",
                    {
                      model: {
                        value: _vm.form.mallProduct.usedStatus,
                        callback: function ($$v) {
                          _vm.$set(_vm.form.mallProduct, "usedStatus", $$v)
                        },
                        expression: "form.mallProduct.usedStatus",
                      },
                    },
                    _vm._l(
                      _vm.MallProductUsedStatusTrans,
                      function (item, index) {
                        return _c("el-option", {
                          key: index,
                          attrs: { label: item, value: index },
                        })
                      }
                    ),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "SKU sản phẩm", prop: "mallProduct.sku" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.form.mallProduct.sku,
                      callback: function ($$v) {
                        _vm.$set(_vm.form.mallProduct, "sku", $$v)
                      },
                      expression: "form.mallProduct.sku",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }