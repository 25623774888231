var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "basic-info" } },
    [
      _c(
        "el-card",
        { attrs: { shadow: "always", "body-style": { padding: "20px" } } },
        [
          _c("div", { attrs: { slot: "header" }, slot: "header" }, [
            _c("h2", [_vm._v("Thông tin cơ bản")]),
          ]),
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                model: _vm.form,
                rules: _vm.rules,
                "label-width": "200px",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "Code", prop: "eVoucher.code" } },
                [
                  _c(
                    "el-input",
                    {
                      attrs: { disabled: _vm.status == "update" },
                      model: {
                        value: _vm.form.eVoucher.code,
                        callback: function ($$v) {
                          _vm.$set(_vm.form.eVoucher, "code", $$v)
                        },
                        expression: "form.eVoucher.code",
                      },
                    },
                    [
                      _c("template", { slot: "prepend" }, [
                        _vm._v(_vm._s(_vm.preCode)),
                      ]),
                    ],
                    2
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "Tên e-voucher", prop: "eVoucher.name" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.form.eVoucher.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.form.eVoucher, "name", $$v)
                      },
                      expression: "form.eVoucher.name",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "Thời gian bán e-voucher",
                    prop: "eVoucher.startAt",
                  },
                },
                [
                  _c("el-date-picker", {
                    attrs: {
                      type: "daterange",
                      "range-separator": "To",
                      format: "dd/MM/yyyy",
                      "value-format": "timestamp",
                      "start-placeholder": "Ngày bắt đầu",
                      "end-placeholder": "Ngày kết thúc",
                      "picker-options": {
                        disabledDate: _vm.handleDisableDate,
                      },
                    },
                    on: { change: _vm.handleChangeOpenSaleDateRange },
                    model: {
                      value: _vm.form.date,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "date", $$v)
                      },
                      expression: "form.date",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { prop: "expiredAt" } },
                [
                  _c("template", { slot: "label" }, [
                    _c("label", [
                      _vm._v(" Thời gian hết hạn "),
                      _c("br"),
                      _vm._v(" e-voucher "),
                    ]),
                  ]),
                  _c("el-date-picker", {
                    attrs: {
                      type: "date",
                      format: "dd/MM/yyyy",
                      "value-format": "timestamp",
                      disabled: !_vm.form.eVoucher.endAt,
                      "picker-options": {
                        disabledDate: _vm.handleDisableDateUsageExpired,
                      },
                    },
                    model: {
                      value: _vm.form.expiredAt,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "expiredAt", $$v)
                      },
                      expression: "form.expiredAt",
                    },
                  }),
                ],
                2
              ),
              _c(
                "el-form-item",
                { attrs: { label: "Danh mục", prop: "mallCategoryId" } },
                [
                  _c("el-cascader", {
                    staticStyle: { width: "100%" },
                    attrs: {
                      options: _vm.mallCategoryTree,
                      clearable: "",
                      props: _vm.propCustom,
                    },
                    model: {
                      value: _vm.form.mallCategoryId,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "mallCategoryId", $$v)
                      },
                      expression: "form.mallCategoryId",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "Mô tả", prop: "eVoucher.description" } },
                [
                  _c("el-input", {
                    attrs: { type: "textarea", rows: 6 },
                    model: {
                      value: _vm.form.eVoucher.description,
                      callback: function ($$v) {
                        _vm.$set(_vm.form.eVoucher, "description", $$v)
                      },
                      expression: "form.eVoucher.description",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "Hình ảnh (Tỷ lệ: 1/1)",
                    prop: "eVoucher.imageUrl",
                  },
                },
                [
                  _c("SingleImageS3", {
                    staticClass: "upload-image",
                    attrs: {
                      pathImage: _vm.form.eVoucher.imageUrl,
                      prefixFile: _vm.PrefixImage.Promotion,
                      width: 200,
                      height: 200,
                    },
                    on: { "upload:success": _vm.setImage },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }