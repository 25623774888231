var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c("div", { staticClass: "filter-container" }, [
        _c(
          "div",
          { staticClass: "filter-item" },
          [
            _c("label", { attrs: { for: "" } }, [_vm._v("Tìm kiếm")]),
            _c("el-input", {
              attrs: { placeholder: "Tìm khách hàng (SĐT, Tên,...)" },
              nativeOn: {
                keydown: function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  ) {
                    return null
                  }
                  return _vm.fetchData($event)
                },
              },
              model: {
                value: _vm.queryList.search,
                callback: function ($$v) {
                  _vm.$set(_vm.queryList, "search", $$v)
                },
                expression: "queryList.search",
              },
            }),
          ],
          1
        ),
        _vm.currentTab == _vm.mallReturnOrderStatus.Confirm
          ? _c(
              "div",
              { staticClass: "filter-item" },
              [
                _c("label", { attrs: { for: "" } }, [_vm._v("Hạn phản hồi")]),
                _c(
                  "el-select",
                  {
                    attrs: { clearable: "" },
                    on: { change: _vm.fetchData },
                    model: {
                      value: _vm.queryList.isExpired,
                      callback: function ($$v) {
                        _vm.$set(_vm.queryList, "isExpired", $$v)
                      },
                      expression: "queryList.isExpired",
                    },
                  },
                  [
                    _c("el-option", {
                      attrs: { label: "Quá hạn phản hồi", value: true },
                    }),
                    _c("el-option", {
                      attrs: { label: "Chưa quá hạn", value: false },
                    }),
                  ],
                  1
                ),
              ],
              1
            )
          : _vm._e(),
      ]),
      _c(
        "el-tabs",
        {
          attrs: { "tab-position": "top" },
          on: { "tab-click": _vm.fetchData },
          model: {
            value: _vm.currentTab,
            callback: function ($$v) {
              _vm.currentTab = $$v
            },
            expression: "currentTab",
          },
        },
        _vm._l(_vm.summaryData, function (item) {
          return _c(
            "el-tab-pane",
            { key: item.value, attrs: { name: item.value, label: item.label } },
            [
              _c("span", { attrs: { slot: "label" }, slot: "label" }, [
                _c(
                  "div",
                  { staticStyle: { display: "flex", "align-items": "center" } },
                  [
                    _c("div", [_vm._v(_vm._s(item.label))]),
                    _c("el-badge", {
                      attrs: {
                        value: item.total || undefined,
                        type: "primary",
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ]
          )
        }),
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          staticStyle: { width: "100%" },
          attrs: { data: _vm.data, border: "", fit: "" },
        },
        [
          _c("el-table-column", {
            attrs: { width: "60", align: "center", label: "STT" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var $index = ref.$index
                  return [_c("span", [_vm._v(_vm._s($index + 1))])]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { width: "120", align: "center", label: "Code" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [_c("span", [_vm._v(_vm._s(row.code))])]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Sản phẩm", prop: "content", "min-width": "200" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return _vm._l(row.mallReturnOrderDetails, function (product) {
                    return _c("div", { key: product.id }, [
                      _c(
                        "div",
                        {
                          staticStyle: {
                            display: "flex",
                            "margin-bottom": "10px",
                            "align-items": "center",
                          },
                        },
                        [
                          _c("img", {
                            staticStyle: {
                              "border-radius": "10px",
                              "margin-right": "10px",
                            },
                            attrs: {
                              width: "50",
                              height: "50",
                              src: product.mallProduct.thumbnail,
                              alt: "",
                            },
                          }),
                          _c("div", [
                            _c("b", [
                              _c(
                                "p",
                                {
                                  staticClass: "one-line",
                                  staticStyle: {
                                    margin: "0",
                                    "line-height": "150%",
                                    "max-width": "200px",
                                  },
                                },
                                [
                                  _vm._v(
                                    " " + _vm._s(product.mallProduct.name) + " "
                                  ),
                                ]
                              ),
                            ]),
                            _c(
                              "p",
                              {
                                staticStyle: {
                                  margin: "0",
                                  "line-height": "150%",
                                },
                              },
                              [_vm._v(" x" + _vm._s(product.quantity) + " ")]
                            ),
                            _c(
                              "p",
                              {
                                staticStyle: {
                                  margin: "0",
                                  "line-height": "150%",
                                },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.formatNumber(product.price)) +
                                    "đ "
                                ),
                              ]
                            ),
                          ]),
                        ]
                      ),
                    ])
                  })
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              label: "Số tiền hoàn lại",
              prop: "returnMoney",
              align: "right",
              width: "120",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _vm._v(
                      " " + _vm._s(_vm.formatNumber(row.returnMoney)) + "đ "
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Khách hàng", prop: "content", "min-width": "150" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c("div", [
                      _vm._v(
                        _vm._s(row.customer.name) +
                          " - " +
                          _vm._s(row.customer.phone)
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Mã đơn mall", "min-width": "120" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c("div", [_vm._v(" " + _vm._s(row.mallOrder.code) + " ")]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              label: "Phương án hoàn trả",
              prop: "returnMethod",
              align: "center",
              "min-width": "200",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c(
                      "el-tag",
                      {
                        staticStyle: {
                          "white-space": "normal",
                          height: "auto",
                        },
                        attrs: {
                          type: _vm.mallReturnOtherMethodTrans[row.returnMethod]
                            .color,
                          size: "small",
                          effect: "dark",
                        },
                      },
                      [
                        _c("b", [
                          _vm._v(
                            _vm._s(
                              _vm.mallReturnOtherMethodTrans[row.returnMethod]
                                .label
                            )
                          ),
                        ]),
                      ]
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              label: "Trạng thái",
              prop: "status",
              align: "center",
              "min-width": "200",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c(
                      "el-tag",
                      {
                        attrs: {
                          type: _vm.mallReturnOrderStatusTrans[row.status]
                            .color,
                          size: "small",
                          effect: "dark",
                        },
                      },
                      [
                        _c("b", [
                          _vm._v(
                            _vm._s(
                              _vm.mallReturnOrderStatusTrans[row.status].label
                            )
                          ),
                        ]),
                      ]
                    ),
                    _c(
                      "el-tag",
                      {
                        staticStyle: { "margin-top": "10px" },
                        attrs: { size: "small", effect: "light" },
                      },
                      [
                        row.isNeedReturnProduct
                          ? _c("b", [_vm._v("Yêu cầu trả hàng")])
                          : _c("b", [_vm._v("Hoàn tiền ngay")]),
                      ]
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              label: "Vận chuyển",
              prop: "content",
              width: "110px",
              align: "center",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _vm._v(" " + _vm._s(row.carrierType) + " "),
                    row.deliveryStatus
                      ? _c(
                          "el-tag",
                          {
                            attrs: {
                              type: _vm.deliveryStatusTrans[row.deliveryStatus]
                                .color,
                              size: "small",
                              effect: "dark",
                            },
                          },
                          [
                            _c("b", [
                              _vm._v(
                                _vm._s(
                                  _vm.deliveryStatusTrans[row.deliveryStatus]
                                    .label
                                )
                              ),
                            ]),
                          ]
                        )
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _c(
            "el-table-column",
            {
              attrs: { label: "Đếm ngược", prop: "content", width: "140px" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (ref) {
                    var row = ref.row
                    return [
                      _vm._v(" " + _vm._s(_vm.formatDate(row.expiredAt)) + " "),
                      _c("br"),
                      _vm.countRemainingDays(row.expiredAt) > 0
                        ? _c("span", [
                            _vm._v(
                              " Còn " +
                                _vm._s(_vm.countRemainingDays(row.expiredAt)) +
                                " ngày "
                            ),
                          ])
                        : _c("p", [_vm._v("Đã quá hạn")]),
                    ]
                  },
                },
              ]),
            },
            [
              _c(
                "template",
                { slot: "header" },
                [
                  _c("span", [_vm._v("Đếm ngược")]),
                  _c(
                    "el-tooltip",
                    {
                      staticClass: "item",
                      attrs: {
                        effect: "dark",
                        content:
                          "Nếu quá hạn xử lý thì yêu cầu sẽ tự động hoàn tiền cho khách hàng",
                        placement: "top-start",
                      },
                    },
                    [
                      _c("i", {
                        staticClass: "el-icon-question",
                        staticStyle: { "margin-left": "4px" },
                      }),
                    ]
                  ),
                ],
                1
              ),
            ],
            2
          ),
          _c("el-table-column", {
            attrs: {
              label: "Lý do trả hàng",
              prop: "content",
              "min-width": "150",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [_c("div", [_vm._v(" " + _vm._s(row.reason) + " ")])]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              "class-name": "username small-padding fixed-width",
              width: "230px",
              label: "Nhân viên",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    row.confirmedStaff
                      ? _c("el-link", { attrs: { type: "primary" } }, [
                          _vm._v(_vm._s(row.confirmedStaff.name)),
                        ])
                      : _vm._e(),
                    row.rejectedStaff
                      ? _c("el-link", { attrs: { type: "primary" } }, [
                          _vm._v(_vm._s(row.rejectedStaff.name)),
                        ])
                      : _vm._e(),
                    row.confirmedStaff
                      ? _c("span", [_vm._v(" (Nhân viên duyệt)")])
                      : _vm._e(),
                    row.rejectedStaff
                      ? _c("span", [_vm._v(" (Nhân viên từ chối)")])
                      : _vm._e(),
                    row.confirmedAt
                      ? _c("div", [
                          _c("label", { attrs: { for: "" } }, [
                            _vm._v("Thời gian duyệt: "),
                          ]),
                          _c("span", [
                            _vm._v(
                              _vm._s(_vm.$formatDateTime(row.confirmedAt))
                            ),
                          ]),
                        ])
                      : _vm._e(),
                    row.rejectedAt
                      ? _c("div", [
                          _c("label", { attrs: { for: "" } }, [
                            _vm._v("Thời gian từ chối: "),
                          ]),
                          _c("span", [
                            _vm._v(_vm._s(_vm.$formatDateTime(row.rejectedAt))),
                          ]),
                        ])
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Thao tác", align: "center", width: "170" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    row.status == _vm.mallReturnOrderStatus.Confirm
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "primary", size: "mini" },
                            on: {
                              click: function ($event) {
                                return _vm.handleRefund(row)
                              },
                            },
                          },
                          [_vm._v("Xác nhận hoàn trả")]
                        )
                      : _vm._e(),
                    row.status == _vm.mallReturnOrderStatus.MallConfirm &&
                    row.deliveryStatus
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "primary", size: "mini" },
                            on: {
                              click: function ($event) {
                                return _vm.confirmReceiveReturn(row)
                              },
                            },
                          },
                          [_vm._v("Đã nhận lại hàng")]
                        )
                      : _vm._e(),
                    row.status == _vm.mallReturnOrderStatus.Confirm &&
                    !row.isComplainReturnOrder
                      ? _c(
                          "el-button",
                          {
                            staticStyle: { "margin-top": "5px" },
                            attrs: { type: "danger", size: "mini" },
                            on: {
                              click: function ($event) {
                                return _vm.onRejectReturnModal(
                                  row,
                                  _vm.mallComplainType.ReturnOrder
                                )
                              },
                            },
                          },
                          [_vm._v("Khiếu nại")]
                        )
                      : _vm._e(),
                    row.status == _vm.mallReturnOrderStatus.MallConfirm &&
                    row.deliveryStatus &&
                    !row.isComplainReturnProduct
                      ? _c(
                          "el-button",
                          {
                            staticStyle: { "margin-top": "5px" },
                            attrs: { type: "danger", size: "mini" },
                            on: {
                              click: function ($event) {
                                return _vm.onRejectReturnModal(
                                  row,
                                  _vm.mallComplainType.ReturnProduct
                                )
                              },
                            },
                          },
                          [_vm._v("Khiếu nại hàng trả")]
                        )
                      : _vm._e(),
                    row.status == _vm.mallReturnOrderStatus.MallConfirm &&
                    row.carrierType &&
                    !row.deliveryStatus
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "primary", size: "mini" },
                            on: {
                              click: function ($event) {
                                return _vm.createShip(row)
                              },
                            },
                          },
                          [_vm._v("Lấy hàng")]
                        )
                      : _vm._e(),
                    row.status == _vm.mallReturnOrderStatus.MallComplain
                      ? _c(
                          "el-button",
                          {
                            attrs: { size: "mini" },
                            on: {
                              click: function ($event) {
                                return _vm.viewComplain(row)
                              },
                            },
                          },
                          [_vm._v("Xem khiếu nại")]
                        )
                      : _vm._e(),
                    row.status == _vm.mallReturnOrderStatus.Confirm &&
                    row.isComplainReturnOrder
                      ? _c("el-tooltip", { attrs: { placement: "top" } }, [
                          _c(
                            "div",
                            { attrs: { slot: "content" }, slot: "content" },
                            [
                              _vm._v(
                                " Do khiếu nại của bạn trước đó đã bị admin từ chối "
                              ),
                              _c("br"),
                              _vm._v(" nên không thể khiếu nại lần nữa "),
                            ]
                          ),
                          _c("span", { staticStyle: { color: "red" } }, [
                            _vm._v("Không thể khiếu nại "),
                            _c("span", {
                              staticClass: "el-icon-warning-outline",
                            }),
                          ]),
                        ])
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c("pagination", {
        attrs: {
          total: _vm.total,
          page: _vm.queryList.page,
          limit: _vm.queryList.limit,
        },
        on: {
          "update:page": function ($event) {
            return _vm.$set(_vm.queryList, "page", $event)
          },
          "update:limit": function ($event) {
            return _vm.$set(_vm.queryList, "limit", $event)
          },
          pagination: _vm.fetchData,
        },
      }),
      _c("CancelReasonModal", {
        ref: "CancelReasonModal",
        on: { submitOk: _vm.fetchData },
      }),
      _c("create-ship-modal", {
        ref: "CreateShipModal",
        on: {
          onSubmitOK: function () {
            _vm.fetchData()
            _vm.summary()
          },
        },
      }),
      _c("reject-return-modal", {
        ref: "RejectReturnModal",
        on: {
          onSubmitOK: function () {
            _vm.fetchData()
            _vm.summary()
          },
        },
      }),
      _c("complain-modal", { ref: "ComplainModal" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }