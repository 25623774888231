var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        visible: _vm.visible,
        width: "500px",
        top: "30px",
        "append-to-body": "",
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
      },
    },
    [
      _c(
        "video",
        { key: _vm.videoSrc, attrs: { width: "100%", controls: "" } },
        [
          _c("source", { attrs: { src: _vm.videoSrc, type: "video/mp4" } }),
          _vm._v(" Your browser does not support the video tag. "),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }