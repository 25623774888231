var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "product-attribute-container" },
    [
      _c(
        "el-card",
        { attrs: { shadow: "always", "body-style": { padding: "20px" } } },
        [
          _c("div", { attrs: { slot: "header" }, slot: "header" }, [
            _c("h2", [_vm._v("Thông tin bán hàng")]),
          ]),
          _c(
            "div",
            { staticClass: "no-variations" },
            [
              _vm.product.pricingType == _vm.MallProductPricingType.Single
                ? [
                    _c("div", { staticClass: "grid edit-row" }, [
                      _c("div", { staticClass: "edit-label" }, [
                        _c("div", { staticClass: "edit-title" }, [
                          _vm._v("Phân loại hàng"),
                        ]),
                      ]),
                      _c(
                        "div",
                        { staticClass: "edit-text" },
                        [
                          _c("ButtonOutline", {
                            attrs: { label: "Thêm nhóm phân loại" },
                            on: { click: _vm.handleClickAddAttr },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _c("div", { staticClass: "grid edit-row" }, [
                      _c("div", { staticClass: "edit-label" }, [
                        _c("div", { staticClass: "edit-title" }, [
                          _vm._v("* Giá gốc"),
                        ]),
                      ]),
                      _c(
                        "div",
                        { staticClass: "edit-text" },
                        [
                          _c("el-input", {
                            directives: [
                              {
                                name: "mask",
                                rawName: "v-mask",
                                value: {
                                  alias: "decimal",
                                  groupSeparator: ".",
                                  rightAlign: false,
                                  autoGroup: true,
                                  repeat: 20,
                                  autoUnmask: true,
                                  allowMinus: false,
                                },
                                expression:
                                  "{\n                alias: 'decimal',\n                groupSeparator: '.',\n                rightAlign: false,\n                autoGroup: true,\n                repeat: 20,\n                autoUnmask: true,\n                allowMinus: false,\n              }",
                              },
                            ],
                            attrs: { placeholder: "", size: "normal" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "prepend",
                                  fn: function () {
                                    return [_vm._v("đ")]
                                  },
                                  proxy: true,
                                },
                              ],
                              null,
                              false,
                              2636744148
                            ),
                            model: {
                              value: _vm.product.originPrice,
                              callback: function ($$v) {
                                _vm.$set(_vm.product, "originPrice", $$v)
                              },
                              expression: "product.originPrice",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _c("div", { staticClass: "grid edit-row" }, [
                      _c("div", { staticClass: "edit-label" }, [
                        _c("div", { staticClass: "edit-title" }, [
                          _vm._v("* Giá khuyến mãi"),
                        ]),
                      ]),
                      _c(
                        "div",
                        { staticClass: "edit-text" },
                        [
                          _c("el-input", {
                            directives: [
                              {
                                name: "mask",
                                rawName: "v-mask",
                                value: {
                                  alias: "decimal",
                                  groupSeparator: ".",
                                  rightAlign: false,
                                  autoGroup: true,
                                  repeat: 20,
                                  autoUnmask: true,
                                  allowMinus: false,
                                },
                                expression:
                                  "{\n                alias: 'decimal',\n                groupSeparator: '.',\n                rightAlign: false,\n                autoGroup: true,\n                repeat: 20,\n                autoUnmask: true,\n                allowMinus: false,\n              }",
                              },
                            ],
                            attrs: { placeholder: "", size: "normal" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "prepend",
                                  fn: function () {
                                    return [_vm._v("đ")]
                                  },
                                  proxy: true,
                                },
                              ],
                              null,
                              false,
                              2636744148
                            ),
                            model: {
                              value: _vm.product.finalPrice,
                              callback: function ($$v) {
                                _vm.$set(_vm.product, "finalPrice", $$v)
                              },
                              expression: "product.finalPrice",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _c("div", { staticClass: "grid edit-row" }, [
                      _c("div", { staticClass: "edit-label" }, [
                        _c("div", { staticClass: "edit-title" }, [
                          _vm._v("* Kho hàng"),
                        ]),
                      ]),
                      _c(
                        "div",
                        { staticClass: "edit-text" },
                        [
                          _c("el-input", {
                            directives: [
                              {
                                name: "mask",
                                rawName: "v-mask",
                                value: {
                                  alias: "decimal",
                                  groupSeparator: ".",
                                  rightAlign: false,
                                  autoGroup: true,
                                  repeat: 20,
                                  autoUnmask: true,
                                  allowMinus: false,
                                },
                                expression:
                                  "{\n                alias: 'decimal',\n                groupSeparator: '.',\n                rightAlign: false,\n                autoGroup: true,\n                repeat: 20,\n                autoUnmask: true,\n                allowMinus: false,\n              }",
                              },
                            ],
                            attrs: { placeholder: "", size: "normal" },
                            model: {
                              value: _vm.product.quantity,
                              callback: function ($$v) {
                                _vm.$set(_vm.product, "quantity", $$v)
                              },
                              expression: "product.quantity",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ]
                : [
                    _c("div", { staticClass: "grid edit-row" }, [
                      _c("div", { staticClass: "edit-label" }, [
                        _c("div", { staticClass: "edit-title" }, [
                          _vm._v("Nhóm phân loại 1"),
                        ]),
                      ]),
                      _c(
                        "div",
                        { staticClass: "edit-text" },
                        [
                          _c("ProductAttributeEdit", {
                            attrs: {
                              productAttr: _vm.product.attr1,
                              attrNumber: 1,
                              onClose: _vm.handleClose,
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _c("div", { staticClass: "grid edit-row" }, [
                      _c("div", { staticClass: "edit-label" }, [
                        _c("div", { staticClass: "edit-title" }, [
                          _vm._v("Nhóm phân loại 2"),
                        ]),
                      ]),
                      _c(
                        "div",
                        { staticClass: "edit-text" },
                        [
                          _vm.visibleAttr2
                            ? _c("ProductAttributeEdit", {
                                attrs: {
                                  productAttr: _vm.product.attr2,
                                  attrNumber: 2,
                                  onClose: _vm.handleCloseAttr2,
                                },
                              })
                            : _c("ButtonOutline", {
                                attrs: { label: "Thêm" },
                                on: {
                                  click: function () {
                                    return (_vm.visibleAttr2 = true)
                                  },
                                },
                              }),
                        ],
                        1
                      ),
                    ]),
                    _c("div", { staticClass: "grid edit-row" }, [
                      _c("div", { staticClass: "edit-label" }, [
                        _c(
                          "div",
                          {
                            staticClass: "edit-title",
                            staticStyle: { "min-width": "150px" },
                          },
                          [_vm._v(" Danh sách phân loại hàng ")]
                        ),
                      ]),
                      _c(
                        "div",
                        { staticStyle: { flex: "1" } },
                        [
                          _c("ProductAttributeList", {
                            ref: "ProductAttributeList",
                            attrs: {
                              productAttr1: _vm.product.attr1,
                              productAttr2: _vm.product.attr2,
                              visibleAttr2: _vm.visibleAttr2,
                              pricingDetails: _vm.productPricingDetails,
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ],
            ],
            2
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }