var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "product-attr-edit" },
    [
      _c("i", {
        staticClass: "el-icon-close close-edit",
        on: { click: _vm.onClose },
      }),
      _c(
        "div",
        { staticClass: "row-edit", staticStyle: { "margin-bottom": "16px" } },
        [
          _vm._m(0),
          _c(
            "div",
            { staticClass: "edit-text" },
            [
              _c("el-input", {
                attrs: {
                  placeholder:
                    "Nhập tên Nhóm phân loại hàng, ví dụ: màu sắc, kích thước v.v",
                  size: "normal",
                  maxlength: "14",
                  "show-word-limit": "",
                },
                model: {
                  value: _vm.productAttr.name,
                  callback: function ($$v) {
                    _vm.$set(_vm.productAttr, "name", $$v)
                  },
                  expression: "productAttr.name",
                },
              }),
            ],
            1
          ),
        ]
      ),
      _vm._l(_vm.attrValues, function (item, index) {
        return _c("div", { key: index, staticClass: "row-edit" }, [
          _c("div", { staticClass: "edit-label" }, [
            _c("div", { staticClass: "edit-title" }, [
              _vm._v(" " + _vm._s(index == 0 ? "Phân loại" : "") + " "),
            ]),
          ]),
          _c(
            "div",
            { staticClass: "edit-text" },
            [
              _c("el-input", {
                ref: "valueInput",
                refInFor: true,
                attrs: {
                  placeholder: "Nhập phân loại hàng, ví dụ: Trắng, Đỏ v.v",
                  size: "normal",
                  maxlength: "20",
                  "show-word-limit": "",
                  "aria-placeholder": "",
                },
                model: {
                  value: item.value,
                  callback: function ($$v) {
                    _vm.$set(item, "value", $$v)
                  },
                  expression: "item.value",
                },
              }),
            ],
            1
          ),
          _c("div", { staticClass: "edit-action" }, [
            _vm.attrValues.length > 1 || index > 0
              ? _c("i", {
                  staticClass: "el-icon-delete icon-delete",
                  on: {
                    click: function ($event) {
                      return _vm.handleDelete(index)
                    },
                  },
                })
              : _vm._e(),
          ]),
        ])
      }),
      _c("div", { staticClass: "row-edit" }, [
        _vm._m(1),
        _c(
          "div",
          { staticClass: "edit-text" },
          [
            _c("ButtonOutline", {
              attrs: { label: "Thêm phân loại hàng" },
              on: { click: _vm.handleAddValue },
            }),
          ],
          1
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "edit-label" }, [
      _c("div", { staticClass: "edit-title" }, [_vm._v(" Tên nhóm ")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "edit-label" }, [
      _c("div", { staticClass: "edit-title" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }