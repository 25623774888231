var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "button",
    {
      staticClass: "btn-outline",
      style: _vm.containerStyle,
      on: { click: _vm.onClick },
    },
    [
      _c("span", { staticClass: "label", style: _vm.labelStyle }, [
        _c("i", { class: _vm.iconClass }),
        _vm._v(" " + _vm._s(_vm.label) + " "),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }