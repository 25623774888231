"use strict";

var _interopRequireDefault = require("C:/Users/Admin/Documents/028web_mall/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.deliveryStatusTrans = exports.EDeliveryStatus = void 0;
var _defineProperty2 = _interopRequireDefault(require("C:/Users/Admin/Documents/028web_mall/node_modules/@babel/runtime/helpers/defineProperty.js"));
var EDeliveryStatus;
(function (EDeliveryStatus) {
  EDeliveryStatus["Pending"] = "PENDING";
  EDeliveryStatus["Picking"] = "PICKING";
  EDeliveryStatus["Picked"] = "PICKED";
  EDeliveryStatus["Delivering"] = "DELIVERING";
  EDeliveryStatus["Delivered"] = "DELIVERED";
  //
  EDeliveryStatus["PickFail"] = "PICK_FAIL";
  EDeliveryStatus["DeliveryFail"] = "DELIVERY_FAIL";
  EDeliveryStatus["Delay"] = "DELAY";
  EDeliveryStatus["WaitingReturn"] = "WAITING_RETURN";
  EDeliveryStatus["Returned"] = "RETURN";
})(EDeliveryStatus || (exports.EDeliveryStatus = EDeliveryStatus = {}));
var deliveryStatusTrans = exports.deliveryStatusTrans = (0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)({}, EDeliveryStatus.Pending, {
  label: "Chờ lấy hàng",
  color: "",
  value: EDeliveryStatus.Pending
}), EDeliveryStatus.Picking, {
  label: "Đang lấy",
  color: "",
  value: EDeliveryStatus.Picking
}), EDeliveryStatus.Picked, {
  label: "Đã lấy hàng",
  color: "",
  value: EDeliveryStatus.Picked
}), EDeliveryStatus.Delivering, {
  label: "Đang giao hàng",
  color: "",
  value: EDeliveryStatus.Delivering
}), EDeliveryStatus.Delivered, {
  label: "Đã giao hàng",
  color: "",
  value: EDeliveryStatus.Delivered
}), EDeliveryStatus.PickFail, {
  label: "Lấy hàng thất bại",
  color: "",
  value: EDeliveryStatus.PickFail
}), EDeliveryStatus.DeliveryFail, {
  label: "Giao thất bại",
  color: "",
  value: EDeliveryStatus.DeliveryFail
}), EDeliveryStatus.Delay, {
  label: "Delay",
  color: "",
  value: EDeliveryStatus.Delay
}), EDeliveryStatus.WaitingReturn, {
  label: "Đang đợi trả hàng",
  color: "",
  value: EDeliveryStatus.WaitingReturn
}), EDeliveryStatus.Returned, {
  label: "Đã trả hàng",
  color: "",
  value: EDeliveryStatus.Returned
});