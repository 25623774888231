"use strict";

var _interopRequireDefault = require("C:/Users/Admin/Documents/028web_mall/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.generateProp = exports.fontStyleName = exports.excludesFontStyleName = void 0;
require("core-js/modules/es.object.to-string.js");
require("core-js/modules/web.dom-collections.for-each.js");
var _objectSpread2 = _interopRequireDefault(require("C:/Users/Admin/Documents/028web_mall/node_modules/@babel/runtime/helpers/objectSpread2.js"));
var _date = require("./date");
var fontStyleName = exports.fontStyleName = {
  name: 'Times New Roman',
  family: 4,
  size: 12
};
var generateProp = exports.generateProp = function generateProp(obj, prop, format) {
  try {
    if (!prop) {
      return "";
    }
    var tmp = (0, _objectSpread2.default)({}, obj);
    var split = prop.split(".");
    split.forEach(function (sp) {
      tmp = tmp[sp];
    });
    if (format == "date") {
      return (0, _date.formatDate)(tmp);
    }
    if (tmp == null) {
      return "--";
    }
    return tmp;
  } catch (error) {
    return "";
  }
};
var excludesFontStyleName = exports.excludesFontStyleName = ['name', 'city.nameWithType', 'ward.nameWithType', 'district.nameWithType', 'driverProfile.permanentAddress', 'driverProfile.stayingAddress', 'address', 'order.customer.name', 'district', 'city', 'ward', 'driver.name', 'creater.name', 'staff.name', 'company.name', 'customer.name', 'sender.name', 'receiver.name'];