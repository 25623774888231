var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        visible: _vm.visible,
        title: "Chọn đơn vị vận chuyển",
        width: "500px",
        top: "30px",
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: { model: _vm.form, rules: _vm.rules, "label-position": "top" },
        },
        [
          _c(
            "el-form-item",
            { attrs: { prop: "carrierType", label: "Đơn vị vận chuyển" } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "100%" },
                  model: {
                    value: _vm.form.carrierType,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "carrierType", $$v)
                    },
                    expression: "form.carrierType",
                  },
                },
                _vm._l(
                  Object.values(_vm.returnCarrierTypeTrans),
                  function (item) {
                    return _c("el-option", {
                      key: item.value,
                      attrs: { label: item.title, value: item.value },
                    })
                  }
                ),
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "span",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "el-button",
            {
              staticStyle: { "margin-right": "10px" },
              on: {
                click: function ($event) {
                  _vm.visible = false
                },
              },
            },
            [_vm._v("Đóng")]
          ),
          _c(
            "el-button",
            {
              attrs: {
                slot: "reference",
                type: "primary",
                loading: _vm.loading,
              },
              on: { click: _vm.handleRefund },
              slot: "reference",
            },
            [_vm._v("Xác nhận hoàn trả")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }