var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-upload",
    {
      staticClass: "upload-file",
      attrs: {
        action: "",
        "on-change": _vm.handleChange,
        "file-list": _vm.fileList,
        "before-upload": _vm.beforeUpload,
        drag: "",
      },
      on: { "on-remove": _vm.handleRemove },
    },
    [
      _vm.isProgressing
        ? _c("el-progress", {
            attrs: { type: "circle", percentage: _vm.percentUpload },
          })
        : [
            _c("i", { staticClass: "el-icon-upload" }),
            _c("div", { staticClass: "el-upload__text" }, [
              _vm._v("Kéo thả hoặc "),
              _c("em", [_vm._v("click vào để upload")]),
            ]),
          ],
      _c(
        "div",
        { staticClass: "el-upload__tip", attrs: { slot: "tip" }, slot: "tip" },
        [_vm._v(_vm._s(_vm.description))]
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }