var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "app-container" },
      [
        _c(
          "el-tabs",
          {
            on: { "tab-click": _vm.handleChangeTab },
            model: {
              value: _vm.listQuery.productType,
              callback: function ($$v) {
                _vm.$set(_vm.listQuery, "productType", $$v)
              },
              expression: "listQuery.productType",
            },
          },
          [
            _c("el-tab-pane", {
              key: _vm.MallOrderProductType.MallProduct,
              attrs: {
                label: "Sản phẩm",
                name: _vm.MallOrderProductType.MallProduct,
              },
            }),
            _c("el-tab-pane", {
              key: _vm.MallOrderProductType.EVoucher,
              attrs: {
                label: "E-voucher",
                name: _vm.MallOrderProductType.EVoucher,
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "filter-container" },
          [
            _c(
              "div",
              { staticClass: "filter-item" },
              [
                _c("label", { attrs: { for: "" } }, [_vm._v("Tìm kiếm")]),
                _c("el-input", {
                  staticStyle: { width: "300px" },
                  attrs: {
                    "prefix-icon": "el-icon-search",
                    placeholder: "Tìm code, tên, sđt",
                  },
                  nativeOn: {
                    keyup: function ($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      ) {
                        return null
                      }
                      return _vm.getTableData($event)
                    },
                  },
                  model: {
                    value: _vm.listQuery.search,
                    callback: function ($$v) {
                      _vm.$set(_vm.listQuery, "search", $$v)
                    },
                    expression: "listQuery.search",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "filter-item" },
              [
                _c("label", { attrs: { for: "" } }, [
                  _vm._v("Trạng thái đơn hàng"),
                ]),
                _c(
                  "el-select",
                  {
                    attrs: { placeholder: "Trạng thái đơn hàng" },
                    model: {
                      value: _vm.listQuery.orderStatus,
                      callback: function ($$v) {
                        _vm.$set(_vm.listQuery, "orderStatus", $$v)
                      },
                      expression: "listQuery.orderStatus",
                    },
                  },
                  [
                    _c("el-option", {
                      attrs: { label: "Tất cả", value: null },
                    }),
                    _vm._l(_vm.MallOrderStatusTrans, function (name, key) {
                      return _c("el-option", {
                        key: key,
                        attrs: { label: name, value: key },
                      })
                    }),
                  ],
                  2
                ),
              ],
              1
            ),
            _c(
              "el-button",
              {
                staticClass: "filter-item",
                staticStyle: { transform: "translateY(10px)" },
                attrs: { type: "primary", icon: "el-icon-search" },
                on: {
                  click: function ($event) {
                    return _vm.getTableData()
                  },
                },
              },
              [_vm._v("Tìm")]
            ),
          ],
          1
        ),
        _c(
          "el-table",
          {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.listLoading,
                expression: "listLoading",
              },
            ],
            ref: "table",
            attrs: {
              data: _vm.tableData,
              border: "",
              fit: "",
              "row-key": "id",
            },
          },
          [
            _c("el-table-column", {
              attrs: {
                "class-name": "text-center",
                type: "index",
                width: "50",
              },
            }),
            _c("el-table-column", {
              attrs: {
                sortable: "",
                label: "Code",
                prop: "code",
                "min-width": "200px",
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (ref) {
                    var row = ref.row
                    return [
                      _c("ul", { staticClass: "list-code" }, [
                        _c("li", [
                          _c("label", { attrs: { for: "" } }),
                          _c("span", { staticStyle: { "font-size": "20px" } }, [
                            _c("b", [_vm._v(_vm._s(row.code))]),
                          ]),
                        ]),
                        _c("li", [
                          _c("label", { attrs: { for: "" } }),
                          _c("span", [
                            _vm._v(_vm._s(_vm.formatDateTime(row.dateCreated))),
                          ]),
                        ]),
                      ]),
                      row.status == _vm.MallOrderStatus.Pending
                        ? _c(
                            "div",
                            {
                              staticStyle: {
                                "text-align": "center",
                                "margin-bottom": "10px",
                              },
                            },
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: {
                                    type: "danger",
                                    loading: row.loadingAccept,
                                    size: "mini",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleCancel(row)
                                    },
                                  },
                                },
                                [_vm._v("Huỷ")]
                              ),
                              _c(
                                "el-button",
                                {
                                  attrs: {
                                    type: "primary",
                                    loading: row.loadingAccept,
                                    size: "mini",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleConfirm(row)
                                    },
                                  },
                                },
                                [_vm._v("Xác nhận đơn")]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      row.shipCode
                        ? _c(
                            "div",
                            { staticStyle: { "text-align": "center" } },
                            [_c("el-tag", [_vm._v(_vm._s(row.shipCode))])],
                            1
                          )
                        : _vm._e(),
                      row.status == _vm.MallOrderStatus.Delay
                        ? _c(
                            "el-button",
                            {
                              staticStyle: {
                                width: "100%",
                                "margin-top": "10px",
                              },
                              attrs: {
                                type: "primary",
                                loading: row.loadingAccept,
                                size: "mini",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.handleUpdateDelayNote(row)
                                },
                              },
                            },
                            [_vm._v(" Cập nhật ghi chú delay")]
                          )
                        : _vm._e(),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: { label: "Thông tin", prop: "startName", width: "300" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (ref) {
                    var row = ref.row
                    return [
                      _c("ul", { staticStyle: { padding: "0" } }, [
                        _c("li", [
                          _c("label", { attrs: { for: "" } }, [
                            _vm._v("Người đặt: "),
                          ]),
                          row.customer
                            ? _c("span", [
                                _vm._v(
                                  " " +
                                    _vm._s(row.customer.name) +
                                    " - " +
                                    _vm._s(row.customer.phone) +
                                    " "
                                ),
                              ])
                            : _c("span", [_vm._v("--")]),
                        ]),
                        row.productType == _vm.MallOrderProductType.MallProduct
                          ? _c("li", [
                              _c("label", { attrs: { for: "" } }, [
                                _vm._v("Người nhận: "),
                              ]),
                              row.receiverName
                                ? _c("span", [
                                    _vm._v(
                                      " " +
                                        _vm._s(row.receiverName) +
                                        " - " +
                                        _vm._s(row.receiverPhone) +
                                        " "
                                    ),
                                  ])
                                : _c("span", [_vm._v("--")]),
                            ])
                          : _vm._e(),
                        row.productType == _vm.MallOrderProductType.MallProduct
                          ? _c("li", [
                              _c("label", { attrs: { for: "" } }, [
                                _vm._v("Điểm giao: "),
                              ]),
                              _c("span", [_vm._v(_vm._s(row.receiverAddress))]),
                            ])
                          : _vm._e(),
                        row.productType == _vm.MallOrderProductType.MallProduct
                          ? _c("li", [
                              _c("label", { attrs: { for: "" } }, [
                                _vm._v("Khoảng cách: "),
                              ]),
                              _c("span", [
                                _vm._v(
                                  _vm._s(_vm.formatNumberVN(row.distance)) +
                                    " Km"
                                ),
                              ]),
                            ])
                          : _vm._e(),
                        _c("li", [
                          _c("label", { attrs: { for: "" } }, [
                            _vm._v("Trạng thái: "),
                          ]),
                          _c(
                            "span",
                            [
                              _c(
                                "el-tag",
                                {
                                  attrs: {
                                    type:
                                      _vm.tagOptions[row.status] &&
                                      _vm.tagOptions[row.status].type,
                                    effect:
                                      _vm.tagOptions[row.status] &&
                                      _vm.tagOptions[row.status].effect,
                                  },
                                },
                                [
                                  _vm._v(
                                    _vm._s(_vm.MallOrderStatusTrans[row.status])
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ]),
                        row.status == _vm.MallOrderStatus.Delay && row.delayNote
                          ? _c("li", [
                              _c("label", { attrs: { for: "" } }, [
                                _vm._v("Ghi chú delay: "),
                              ]),
                              _c(
                                "span",
                                [
                                  _vm._v(" " + _vm._s(row.delayNote) + " "),
                                  _c(
                                    "el-button",
                                    {
                                      attrs: { type: "text", size: "mini" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.handleUpdateDelayNote(row)
                                        },
                                      },
                                    },
                                    [_vm._v("Chỉnh sửa")]
                                  ),
                                ],
                                1
                              ),
                            ])
                          : _vm._e(),
                        _c("li", [
                          _c("label", { attrs: { for: "" } }, [
                            _vm._v("Loại sản phẩm: "),
                          ]),
                          _c("span", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.MallOrderProductTypeTrans[row.productType]
                                ) +
                                " "
                            ),
                          ]),
                        ]),
                        row.productType == _vm.MallOrderProductType.EVoucher
                          ? _c("li", [
                              _c("label", { attrs: { for: "" } }, [
                                _vm._v("Tình trạng sử dụng: "),
                              ]),
                              _c("span", [
                                _vm._v(
                                  " " +
                                    _vm._s(row.totalIsUsed) +
                                    "/" +
                                    _vm._s(row.totalIsUsed + row.totalNotUsed) +
                                    " "
                                ),
                              ]),
                            ])
                          : _vm._e(),
                      ]),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: {
                label: "Chi tiết",
                "min-width": "250px",
                "header-align": "center",
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (ref) {
                    var row = ref.row
                    return [
                      _c(
                        "ul",
                        { staticClass: "food-list" },
                        _vm._l(row.mallOrderDetails, function (item, index) {
                          return _c(
                            "li",
                            { key: index, staticClass: "food-item" },
                            [
                              _c("div", [
                                item.mallProduct
                                  ? _c("span", { staticClass: "name" }, [
                                      _vm._v(_vm._s(item.mallProduct.name)),
                                    ])
                                  : _vm._e(),
                                item.eVoucherLines.length
                                  ? _c("span", { staticClass: "name" }, [
                                      _vm._v(
                                        _vm._s(
                                          item.eVoucherLines[0].eVoucher.name
                                        )
                                      ),
                                    ])
                                  : _vm._e(),
                                _c("span", { staticClass: "amount" }, [
                                  _vm._v(" x " + _vm._s(item.quantity)),
                                ]),
                                item.attr1Value || item.attr2Value
                                  ? _c("div", [
                                      _c(
                                        "span",
                                        { staticClass: "single-price" },
                                        [
                                          _vm._v(
                                            "(" +
                                              _vm._s(item.attr1Value) +
                                              _vm._s(
                                                item.attr2Value
                                                  ? ", " + item.attr2Value
                                                  : ""
                                              ) +
                                              ")"
                                          ),
                                        ]
                                      ),
                                    ])
                                  : _vm._e(),
                                _c("div", [
                                  _c("span", { staticClass: "single-price" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("toThousandFilter")(item.price)
                                      )
                                    ),
                                  ]),
                                ]),
                              ]),
                              _c("div", [
                                _c("span", { staticClass: "price" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("toThousandFilter")(
                                        item.quantity * item.price
                                      )
                                    )
                                  ),
                                ]),
                              ]),
                            ]
                          )
                        }),
                        0
                      ),
                      _c("el-divider"),
                      row.productType == _vm.MallOrderProductType.MallProduct
                        ? _c(
                            "div",
                            { staticStyle: { "text-align": "right" } },
                            [
                              _c("label", { attrs: { for: "" } }, [
                                _vm._v("Tổng tiền hàng: "),
                              ]),
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("toThousandFilter")(row.moneyProduct)
                                  )
                                ),
                              ]),
                            ]
                          )
                        : _vm._e(),
                      row.productType == _vm.MallOrderProductType.MallProduct
                        ? _c(
                            "div",
                            { staticStyle: { "text-align": "right" } },
                            [
                              _c("label", { attrs: { for: "" } }, [
                                _vm._v("Phí vận chuyển: "),
                              ]),
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("toThousandFilter")(row.shipFee)
                                  )
                                ),
                              ]),
                            ]
                          )
                        : _vm._e(),
                      row.moneyDiscount > 0
                        ? _c(
                            "div",
                            { staticStyle: { "text-align": "right" } },
                            [
                              _c("label", { attrs: { for: "" } }, [
                                _vm._v("Tiền khuyến mãi: "),
                              ]),
                              _c("span", [
                                _vm._v(
                                  "-" +
                                    _vm._s(
                                      _vm._f("toThousandFilter")(
                                        row.moneyDiscount
                                      )
                                    )
                                ),
                              ]),
                            ]
                          )
                        : _vm._e(),
                      _c("div", { staticStyle: { "text-align": "right" } }, [
                        _c("label", { attrs: { for: "" } }, [
                          _vm._v("Tổng thanh toán: "),
                        ]),
                        _c("span", [
                          _vm._v(
                            _vm._s(_vm._f("toThousandFilter")(row.finalPrice))
                          ),
                        ]),
                      ]),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: { label: "Thao tác", width: "100" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (ref) {
                    var row = ref.row
                    return [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary", size: "mini" },
                          on: {
                            click: function ($event) {
                              return _vm.handleView(row)
                            },
                          },
                        },
                        [_vm._v(" Chi tiết ")]
                      ),
                    ]
                  },
                },
              ]),
            }),
          ],
          1
        ),
        _c("pagination", {
          attrs: {
            total: _vm.total,
            page: _vm.listQuery.page,
            limit: _vm.listQuery.limit,
          },
          on: {
            "update:page": function ($event) {
              return _vm.$set(_vm.listQuery, "page", $event)
            },
            "update:limit": function ($event) {
              return _vm.$set(_vm.listQuery, "limit", $event)
            },
            pagination: _vm.getTableData,
          },
        }),
        _c("detail-order-dialog", { ref: "DetailOrderDialog" }),
        _c("delay-note", {
          ref: "DelayNote",
          on: {
            onSubmitOk: function () {
              return _vm.getTableData()
            },
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }