"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.PrefixImage = void 0;
var PrefixImage;
(function (PrefixImage) {
  PrefixImage["Food"] = "food";
  PrefixImage["Mall"] = "mall";
  PrefixImage["Promotion"] = "promotion";
  PrefixImage["Banner"] = "banner";
})(PrefixImage || (exports.PrefixImage = PrefixImage = {}));