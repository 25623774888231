"use strict";

var _interopRequireDefault = require("C:/Users/Admin/Documents/028web_mall/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.mallOrderApi = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
var mallOrderApi = exports.mallOrderApi = {
  findAll: function findAll(params, hash) {
    return (0, _request.default)({
      url: "/mallOrder",
      params: params
    });
  },
  detail: function detail(id, hash) {
    return (0, _request.default)({
      url: "/mallOrder/".concat(id),
      headers: {
        "hash-pin-code": hash
      }
    });
  },
  confirm: function confirm(id) {
    return (0, _request.default)({
      url: "/mallOrder/".concat(id, "/confirm"),
      method: "put"
    });
  },
  delayNote: function delayNote(id, data) {
    return (0, _request.default)({
      url: "/mallOrder/".concat(id, "/delayNote"),
      method: "patch",
      data: data
    });
  },
  cancel: function cancel(id) {
    return (0, _request.default)({
      url: "/mallOrder/".concat(id, "/cancel"),
      method: "put"
    });
  }
};