var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c("h3", [_vm._v("Công cụ Marketing")]),
      _c(
        "el-row",
        { attrs: { gutter: 5 } },
        [
          _c(
            "el-col",
            { attrs: { span: 8 } },
            [
              _c("marketing-item", {
                attrs: {
                  title: "Mã giảm giá của shop",
                  content:
                    "Công cụ tăng đơn hàng bằng cách tạo mã giảm giá tặng cho người mua",
                  icon: _vm.couponSvg,
                  onClick: _vm.handleClickVoucher,
                },
              }),
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 8 } },
            [
              _c("marketing-item", {
                attrs: {
                  title: "Flash Sale của Shop",
                  content:
                    "Công cụ giúp tăng doanh số bằng cách tạo khuyến mãi khủng trong các khung giờ nhất định",
                  icon: _vm.couponSvg,
                  onClick: _vm.handleClickFlashSale,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }