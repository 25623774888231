"use strict";

var _interopRequireDefault = require("C:/Users/Admin/Documents/028web_mall/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ContentDefineType = exports.CONTENT_DEFINE_PARAMETER = void 0;
var _defineProperty2 = _interopRequireDefault(require("C:/Users/Admin/Documents/028web_mall/node_modules/@babel/runtime/helpers/defineProperty.js"));
var ContentDefineType;
(function (ContentDefineType) {
  ContentDefineType["ShareStore"] = "SHARE_STORE";
  ContentDefineType["MerchantShareDriver"] = "MERCHANT_SHARE_DRIVER";
})(ContentDefineType || (exports.ContentDefineType = ContentDefineType = {}));
var CONTENT_DEFINE_PARAMETER = exports.CONTENT_DEFINE_PARAMETER = (0, _defineProperty2.default)({}, ContentDefineType.ShareStore, 'Nội dung chia sẻ của cửa hàng');