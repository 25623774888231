var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _vm.hash
        ? _c(
            "div",
            [
              _c(
                "el-card",
                {
                  attrs: {
                    shadow: "always",
                    "body-style": { padding: "20px" },
                  },
                },
                [
                  _c("div", { attrs: { slot: "header" }, slot: "header" }, [
                    _c("h2", { staticStyle: { margin: "0" } }, [
                      _vm._v("Kiểm tra e-voucher"),
                    ]),
                  ]),
                  _c(
                    "el-form",
                    {
                      ref: "form",
                      attrs: {
                        model: _vm.form,
                        rules: _vm.rules,
                        "label-width": "200px",
                      },
                      nativeOn: {
                        submit: function ($event) {
                          $event.preventDefault()
                        },
                      },
                    },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "Mã e-voucher",
                            prop: "eVoucherCode",
                          },
                        },
                        [
                          _c(
                            "el-input",
                            {
                              nativeOn: {
                                keyup: function ($event) {
                                  if (
                                    !$event.type.indexOf("key") &&
                                    _vm._k(
                                      $event.keyCode,
                                      "enter",
                                      13,
                                      $event.key,
                                      "Enter"
                                    )
                                  ) {
                                    return null
                                  }
                                  return _vm.handleCheck($event)
                                },
                              },
                              model: {
                                value: _vm.form.eVoucherCode,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "eVoucherCode", $$v)
                                },
                                expression: "form.eVoucherCode",
                              },
                            },
                            [
                              _c(
                                "template",
                                { slot: "append" },
                                [
                                  _c(
                                    "el-button",
                                    {
                                      attrs: { type: "primary" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.handleScan()
                                        },
                                      },
                                    },
                                    [_vm._v("Scan QR Code")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "footer-check" },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary", loading: _vm.loading },
                          on: {
                            click: function ($event) {
                              return _vm.handleCheck()
                            },
                          },
                        },
                        [_vm._v("Kiểm tra")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm.dataCheck
                ? _c(
                    "el-card",
                    {
                      staticStyle: { "margin-top": "8px" },
                      attrs: {
                        shadow: "always",
                        "body-style": { padding: "20px" },
                      },
                    },
                    [
                      _c("div", { attrs: { slot: "header" }, slot: "header" }, [
                        _c("h2", { staticStyle: { margin: "0" } }, [
                          _vm._v("Chi tiết e-voucher"),
                        ]),
                      ]),
                      _c("div", [
                        _c("img", {
                          staticStyle: { "border-radius": "8px" },
                          attrs: {
                            width: "200",
                            src: _vm.$url.image(
                              _vm.dataCheck.eVoucher.imageUrl
                            ),
                            alt: "",
                          },
                        }),
                        _c(
                          "ul",
                          {
                            staticClass: "voucher-info-list",
                            staticStyle: { "padding-left": "8px" },
                          },
                          [
                            _c("li", { staticClass: "list-item" }, [
                              _c("label", { attrs: { for: "" } }, [
                                _vm._v("Tên e-voucher:"),
                              ]),
                              _c("span", [
                                _vm._v(
                                  " " + _vm._s(_vm.dataCheck.eVoucher.name)
                                ),
                              ]),
                            ]),
                            _c(
                              "li",
                              { staticClass: "list-item" },
                              [
                                _c("label", { attrs: { for: "" } }, [
                                  _vm._v("Mã e-voucher:"),
                                ]),
                                _c("span", [
                                  _vm._v(" " + _vm._s(_vm.voucherCode) + " - "),
                                ]),
                                this.dataCheck.status ==
                                _vm.EVoucherValidStatus.Valid
                                  ? _c(
                                      "el-tag",
                                      {
                                        attrs: {
                                          type: "success",
                                          effect: "dark",
                                        },
                                      },
                                      [_vm._v(" Chưa sử dụng ")]
                                    )
                                  : _c(
                                      "el-tag",
                                      {
                                        attrs: {
                                          type: "danger",
                                          effect: "dark",
                                        },
                                      },
                                      [_vm._v(" Đã sử dụng ")]
                                    ),
                              ],
                              1
                            ),
                            _c("li", { staticClass: "list-item" }, [
                              _c("label", { attrs: { for: "" } }, [
                                _vm._v("Người mua:"),
                              ]),
                              _c("span", [
                                _vm._v(
                                  " " + _vm._s(_vm.dataCheck.customer.name)
                                ),
                              ]),
                            ]),
                            _c("li", { staticClass: "list-item" }, [
                              _c("label", { attrs: { for: "" } }, [
                                _vm._v("Phương thức thanh toán:"),
                              ]),
                              _c("span", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.MallPaymentTypeTrans[
                                        _vm.dataCheck.mallOrder.paymentType
                                      ]
                                    )
                                ),
                              ]),
                            ]),
                            _c("li", { staticClass: "list-item" }, [
                              _c("label", { attrs: { for: "" } }, [
                                _vm._v("Giá bán:"),
                              ]),
                              _c("span", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$formatNumberVN(
                                        _vm.dataCheck.eVoucher.price
                                      )
                                    ) +
                                    "đ"
                                ),
                              ]),
                            ]),
                            _c("li", { staticClass: "list-item" }, [
                              _c("label", { attrs: { for: "" } }, [
                                _vm._v("Giá trị sử dụng:"),
                              ]),
                              _c("span", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$formatNumberVN(
                                        _vm.dataCheck.eVoucher.discountValue
                                      )
                                    ) +
                                    "đ "
                                ),
                              ]),
                            ]),
                            _c("li", { staticClass: "list-item" }, [
                              _c("label", { attrs: { for: "" } }, [
                                _vm._v("Thời gian bán voucher:"),
                              ]),
                              _c("span", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$formatDate(
                                        _vm.dataCheck.eVoucher.startAt
                                      )
                                    ) +
                                    " - " +
                                    _vm._s(
                                      _vm.$formatDate(
                                        _vm.dataCheck.eVoucher.endAt
                                      )
                                    ) +
                                    " "
                                ),
                              ]),
                            ]),
                            _c("li", { staticClass: "list-item" }, [
                              _c("label", { attrs: { for: "" } }, [
                                _vm._v("Thời gian mua:"),
                              ]),
                              _c("span", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$formatDate(
                                        _vm.dataCheck.mallOrder.dateCreated
                                      )
                                    )
                                ),
                              ]),
                            ]),
                            _c(
                              "li",
                              { staticClass: "list-item" },
                              [
                                _c("label", { attrs: { for: "" } }, [
                                  _vm._v("Thời gian sử dụng:"),
                                ]),
                                _c("span", [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$formatDate(
                                          _vm.dataCheck.mallOrder.dateCreated
                                        )
                                      ) +
                                      " - " +
                                      _vm._s(
                                        _vm.$formatDate(
                                          _vm.dataCheck.eVoucher.expiredAt
                                        )
                                      ) +
                                      " "
                                  ),
                                ]),
                                !_vm.checkValidateDate
                                  ? _c(
                                      "el-tag",
                                      {
                                        attrs: {
                                          type: "danger",
                                          effect: "dark",
                                        },
                                      },
                                      [_vm._v(" Đã quá hạn áp dụng ")]
                                    )
                                  : _c(
                                      "el-tag",
                                      {
                                        attrs: {
                                          type: "success",
                                          effect: "dark",
                                        },
                                      },
                                      [_vm._v(" Còn hạn ")]
                                    ),
                              ],
                              1
                            ),
                          ]
                        ),
                      ]),
                      this.dataCheck.status == _vm.EVoucherValidStatus.Valid &&
                      _vm.checkValidateDate
                        ? _c(
                            "div",
                            { staticClass: "footer-check" },
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: {
                                    type: "primary",
                                    loading: _vm.loading,
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleUse()
                                    },
                                  },
                                },
                                [_vm._v("Sử dụng")]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ]
                  )
                : _vm._e(),
              _c("check-dialog", { ref: "CheckDialog" }),
              _c("QrCodeScannerModal", {
                ref: "ScanQRCode",
                on: { "scan:OK": _vm.handleScanOk },
              }),
            ],
            1
          )
        : _c(
            "div",
            {
              staticClass: "app-container",
              staticStyle: { "max-width": "unset" },
            },
            [
              _c(
                "el-row",
                { attrs: { gutter: 20 } },
                [
                  this.$store.state.user.info.isHasPinCode
                    ? _c(
                        "el-col",
                        { attrs: { sm: 24, lg: 24 } },
                        [
                          _c(
                            "el-result",
                            {
                              attrs: {
                                icon: "warning",
                                title: "Yêu cầu mã pin",
                                subTitle:
                                  "Vui lòng nhập mã pin để truy cập trang này",
                              },
                            },
                            [
                              _c(
                                "template",
                                { slot: "extra" },
                                [
                                  _c(
                                    "el-button",
                                    {
                                      attrs: {
                                        type: "primary",
                                        size: "medium",
                                      },
                                      on: { click: _vm.handleShowPinDialog },
                                    },
                                    [_vm._v("Nhập mã")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            2
                          ),
                        ],
                        1
                      )
                    : _c(
                        "el-col",
                        { attrs: { sm: 24, lg: 24 } },
                        [
                          _c(
                            "el-result",
                            {
                              attrs: {
                                icon: "warning",
                                title: "Yêu cầu tạo mã Pin",
                                subTitle:
                                  "Bạn cần phải tạo mã Pin để truy cập vào trang này",
                              },
                            },
                            [
                              _c(
                                "template",
                                { slot: "extra" },
                                [
                                  _c(
                                    "el-button",
                                    {
                                      attrs: {
                                        type: "primary",
                                        size: "medium",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.$router.push({
                                            name: "profile-index",
                                            query: { page: "pin" },
                                          })
                                        },
                                      },
                                    },
                                    [_vm._v("Tạo mã pin")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                ],
                1
              ),
            ],
            1
          ),
      _c("pin-dialog", {
        ref: "PinDialog",
        on: { isValidate: _vm.handleValidated },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }