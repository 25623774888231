var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "sign-up" }, [
    _c(
      "div",
      { staticClass: "sign-up-container" },
      [
        _c(
          "el-form",
          {
            ref: "form",
            staticClass: "sign-up-form",
            attrs: {
              model: _vm.form,
              rules: _vm.rules,
              "label-position": "top",
            },
          },
          [
            _c(
              "section",
              { staticClass: "section-form" },
              [
                _c("div", { staticClass: "logo" }, [
                  _c("img", {
                    staticStyle: { "border-radius": "4px" },
                    attrs: { width: "100", src: _vm.logo, alt: "" },
                  }),
                  _c("h3", { staticClass: "title", attrs: { for: "" } }, [
                    _c("span", [_vm._v("ĐĂNG KÝ MỞ CỬA HÀNG FCLASSMALL ")]),
                  ]),
                ]),
                !_vm.isSingedUp
                  ? [
                      _c("div", [
                        _c("h4", [
                          _c("img", {
                            staticStyle: { "vertical-align": "middle" },
                            attrs: {
                              width: "40",
                              src: require("@/assets/images/icon3.png"),
                            },
                          }),
                          _c(
                            "span",
                            { staticStyle: { "vertical-align": "middle" } },
                            [_vm._v("Thông tin chung")]
                          ),
                        ]),
                      ]),
                      _c(
                        "el-form-item",
                        { attrs: { label: "Cửa hàng của" } },
                        [
                          _c(
                            "el-radio",
                            {
                              attrs: { label: _vm.StoreType.Company },
                              model: {
                                value: _vm.form.type,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "type", $$v)
                                },
                                expression: "form.type",
                              },
                            },
                            [_vm._v("Công ty/Hộ Cá thể")]
                          ),
                        ],
                        1
                      ),
                    ]
                  : _vm._e(),
                _vm.isSingedUp
                  ? _c(
                      "div",
                      { staticClass: "sign-up-success" },
                      [
                        _c("div", { staticClass: "text-center" }, [
                          _c("img", {
                            attrs: {
                              width: "60",
                              src: require("@/assets/images/checked.svg"),
                            },
                          }),
                        ]),
                        _c("p", { attrs: { for: "" } }, [
                          _vm._v(
                            " Cảm ơn bạn đã đăng ký mở cửa hàng FclassMall. Vui lòng đợi ban quản trị phản hồi yêu cầu đăng ký của bạn! "
                          ),
                        ]),
                        _c(
                          "el-button",
                          {
                            staticStyle: { width: "100%", "margin-top": "8px" },
                            attrs: { type: "primary", size: "small" },
                            on: {
                              click: function ($event) {
                                return _vm.$router.replace("/login")
                              },
                            },
                          },
                          [_vm._v("Trở về đăng nhập")]
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
              ],
              2
            ),
            !_vm.isSingedUp
              ? [
                  _vm.form.type == _vm.StoreType.Company
                    ? _c("company-form", { attrs: { form: _vm.form } })
                    : _vm._e(),
                  _c("owner-form", { attrs: { form: _vm.form } }),
                  _c("store-form", { attrs: { form: _vm.form } }),
                ]
              : _vm._e(),
          ],
          2
        ),
        _c(
          "section",
          { staticClass: "bottom" },
          [
            !_vm.isSingedUp
              ? [
                  _c(
                    "div",
                    { staticStyle: { "margin-top": "16px" } },
                    [
                      _c(
                        "label",
                        { staticStyle: { color: "red" }, attrs: { for: "" } },
                        [_vm._v("Lưu ý*")]
                      ),
                      _c("br"),
                      _c(
                        "el-checkbox",
                        {
                          staticClass: "cb-term",
                          model: {
                            value: _vm.isAgree,
                            callback: function ($$v) {
                              _vm.isAgree = $$v
                            },
                            expression: "isAgree",
                          },
                        },
                        [
                          _c(
                            "div",
                            [
                              _vm._v(
                                " Tôi đã đọc, hiểu rõ và đồng ý mức phí dịch vụ, các điều khoản và điều kiện của Hợp đồng Cung ứng dịch vụ thương điện tử này. Đọc điều kiện và điều khoản "
                              ),
                              _c(
                                "el-link",
                                {
                                  attrs: {
                                    type: "primary",
                                    href: _vm.linkTerm,
                                    target: "_blank",
                                  },
                                },
                                [_vm._v("ở đây")]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            [
                              _vm._v(
                                " Tôi đã đọc, hiểu rõ và đồng ý Quy chế hoạt động. Đọc Quy chế hoạt động "
                              ),
                              _c(
                                "el-link",
                                {
                                  attrs: {
                                    type: "primary",
                                    href: _vm.linkRegulation,
                                    target: "_blank",
                                  },
                                },
                                [_vm._v("ở đây")]
                              ),
                            ],
                            1
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-button",
                    {
                      staticStyle: { width: "100%", "margin-top": "16px" },
                      attrs: {
                        loading: _vm.loading,
                        type: "primary",
                        size: "medium",
                        disabled: !_vm.isAgree,
                      },
                      on: { click: _vm.register },
                    },
                    [_vm._v("Đăng ký ngay")]
                  ),
                  _c(
                    "div",
                    {
                      staticStyle: {
                        "text-align": "right",
                        "margin-top": "16px",
                      },
                    },
                    [
                      _c(
                        "el-link",
                        { attrs: { type: "primary" } },
                        [
                          _c("router-link", { attrs: { to: "/login" } }, [
                            _vm._v("Trở về đăng nhập"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._m(0),
                  _vm._m(1),
                ]
              : _vm._e(),
          ],
          2
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("label", { attrs: { for: "" } }, [_vm._v("Ghi chú")]),
      _c("br"),
      _c("ul", [_c("li", [_vm._v("Miễn phí đăng ký")])]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "contact" }, [
      _c("label", { attrs: { for: "" } }, [_vm._v("Liên hệ hướng dẫn")]),
      _c("div", [
        _c("a", { attrs: { href: "tel:024 730 78288" } }, [
          _c("img", {
            attrs: { src: require("@/assets/images/phone.svg"), width: "20" },
          }),
          _vm._v(" Hà Nội: 024 730 78288 "),
        ]),
      ]),
      _c("div", [
        _c("a", { attrs: { href: "tel:028 730 82889" } }, [
          _c("img", {
            attrs: { src: require("@/assets/images/phone.svg"), width: "20" },
          }),
          _vm._v(" Hồ Chí Minh: 028 730 82889 "),
        ]),
      ]),
      _c("div", [
        _c("a", { attrs: { href: "tel:" } }, [
          _c("img", {
            attrs: { src: require("@/assets/images/phone.svg"), width: "20" },
          }),
          _vm._v(" Các tỉnh/thành khác: (Mã vùng) 730 82 88 "),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }