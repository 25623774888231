"use strict";

var _interopRequireDefault = require("C:/Users/Admin/Documents/028web_mall/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.object.to-string.js");
var _objectSpread2 = _interopRequireDefault(require("C:/Users/Admin/Documents/028web_mall/node_modules/@babel/runtime/helpers/objectSpread2.js"));
var _storeNews = require("@/api/store-news");
var _index = _interopRequireDefault(require("@/components/Pagination/index.vue"));
var _NewsModal = _interopRequireDefault(require("./components/NewsModal.vue"));
var _moment = _interopRequireDefault(require("moment"));
var _vuex = require("vuex");
var _default = exports.default = {
  computed: (0, _objectSpread2.default)({}, (0, _vuex.mapState)({
    user: function user(state) {
      return state.user.info;
    }
  })),
  components: {
    Pagination: _index.default,
    NewsModal: _NewsModal.default
  },
  data: function data() {
    return {
      baseUrl: process.env.VUE_APP_BASE_API,
      baseUrlMedia: process.env.VUE_APP_BASE_URL_MEDIA,
      formData: {},
      listQuery: {
        page: 1,
        limit: 10,
        cityId: null,
        districtId: null,
        wardId: null
      },
      total: 0,
      tableData: [],
      listLoading: false,
      loadingExport: false,
      date: [(0, _moment.default)().subtract(1, "months").format("YYYY-MM-DD"), (0, _moment.default)().format("YYYY-MM-DD")]
    };
  },
  created: function created() {
    this.getList();
  },
  watch: {
    date: function date(newVal) {
      this.getList();
    }
  },
  methods: {
    handleUpdate: function handleUpdate(row) {
      this.$refs.newsModal.handleUpdate(row);
    },
    getList: function getList() {
      var _this = this;
      return;
      this.listLoading = true;
      this.listQuery.from = this.date[0];
      this.listQuery.to = this.date[1];
      this.listQuery.cityId = this.user.city && this.user.city.id;
      this.listQuery.districtId = this.user.district && this.user.district.id;
      this.listQuery.wardId = this.user.ward && this.user.ward.id;
      _storeNews.storeNewsApi.findAll(this.listQuery).then(function (res) {
        _this.tableData = res.data.data;
        _this.total = res.data.total;
      }).finally(function () {
        return _this.listLoading = false;
      });
    }
  }
};