"use strict";

var _interopRequireWildcard = require("C:/Users/Admin/Documents/028web_mall/node_modules/@babel/runtime/helpers/interopRequireWildcard.js").default;
var _interopRequireDefault = require("C:/Users/Admin/Documents/028web_mall/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.app = void 0;
require("core-js/modules/es.array.filter.js");
require("core-js/modules/es.object.keys.js");
require("core-js/modules/es.object.to-string.js");
require("core-js/modules/web.dom-collections.for-each.js");
require("C:\\Users\\Admin\\Documents\\028web_mall\\node_modules\\core-js\\modules\\es.array.iterator.js");
require("C:\\Users\\Admin\\Documents\\028web_mall\\node_modules\\core-js\\modules\\es.promise.js");
require("C:\\Users\\Admin\\Documents\\028web_mall\\node_modules\\core-js\\modules\\es.object.assign.js");
require("C:\\Users\\Admin\\Documents\\028web_mall\\node_modules\\core-js\\modules\\es.promise.finally.js");
var _vue = _interopRequireDefault(require("vue"));
require("normalize.css/normalize.css");
var _elementUi = _interopRequireDefault(require("element-ui"));
require("./styles/element-variables.scss");
require("@/styles/index.scss");
require("@/plugins/google-maps");
require("@/plugins/font-awesome");
require("@/plugins/calendar");
var _App = _interopRequireDefault(require("./App.vue"));
var _store = _interopRequireDefault(require("./store"));
var _router = _interopRequireDefault(require("./router"));
require("@/plugins/OneSignal");
require("@/plugins/input-mask");
var _vi = _interopRequireDefault(require("element-ui/lib/locale/lang/vi"));
var _locale = _interopRequireDefault(require("element-ui/lib/locale"));
require("./icons");
require("./permission");
require("./utils/error-log");
var _vueClipboard = _interopRequireDefault(require("vue-clipboard2"));
var filters = _interopRequireWildcard(require("./filters"));
var _date = require("@/utils/date");
var _format = require("@/utils/format");
var _url = require("./utils/url");
var _index2 = _interopRequireDefault(require("@/components/Pagination/index.vue"));
var _vueOtpInput = _interopRequireDefault(require("@bachdgvn/vue-otp-input"));
// a modern alternative to CSS resets

// global css
// import gg maps plugin

// import font awesome;

// import calendar;

// icon
// permission control
// error log

// const VueInputMask = require("vue-inputmask").default;
// Vue.use(VueInputMask);
// global filters

_vue.default.component("Pagination", _index2.default);
_vue.default.component("v-otp-input", _vueOtpInput.default);
_vue.default.prototype.$formatDateTime = _date.formatDateTime;
_vue.default.prototype.$formatDate = _date.formatDate;
_vue.default.prototype.$formatNumberVN = _format.formatNumberVN;
_vue.default.prototype.$formatCurrency = _format.formatCurrency;
_vue.default.prototype.$cloudFrontUrl = process.env.VUE_APP_CLOUD_FRONT_URL;
_vue.default.prototype.$baseUrl = process.env.VUE_APP_BASE_API;
_vue.default.prototype.$baseUrlMedia = process.env.VUE_APP_BASE_URL_MEDIA;
_vue.default.prototype.$url = _url.url;
_locale.default.use(_vi.default);
_vue.default.use(_elementUi.default, {
  size: "small" // set element-ui default size
});
_vueClipboard.default.config.autoSetContainer = true; // add this line
_vue.default.use(_vueClipboard.default);
// register global utility filters
Object.keys(filters).forEach(function (key) {
  _vue.default.filter(key, filters[key]);
});
_vue.default.config.productionTip = false;
_vue.default.prototype.$devMode = process.env.VUE_APP_IS_DEV == "true";
var app = exports.app = new _vue.default({
  el: "#app",
  router: _router.default,
  store: _store.default,
  render: function render(h) {
    return h(_App.default);
  }
});