var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "app-main" },
    [
      _c(
        "transition",
        { attrs: { name: "fade-transform", mode: "out-in" } },
        [
          _c(
            "keep-alive",
            { attrs: { include: _vm.cachedViews } },
            [_c("router-view", { key: _vm.key })],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "right-side" },
        [_c("news", { attrs: { onClickNews: _vm.handleClickNews } })],
        1
      ),
      _c("NewsModal", { ref: "NewsModal" }),
      _c("button", {
        staticStyle: { visibility: "hidden", height: "0" },
        attrs: { id: "play_sound" },
        on: { click: _vm.handlePlayAudio },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }