var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "basic-config" } },
    [
      _c(
        "el-card",
        { attrs: { shadow: "always", "body-style": { padding: "20px" } } },
        [
          _c("div", { attrs: { slot: "header" }, slot: "header" }, [
            _c("h2", [_vm._v("Thiết lập e-voucher")]),
          ]),
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                model: _vm.form,
                rules: _vm.rules,
                "label-width": "200px",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "Giá bán", prop: "eVoucher.price" } },
                [
                  _c(
                    "el-input",
                    {
                      directives: [
                        {
                          name: "mask",
                          rawName: "v-mask",
                          value: {
                            alias: "decimal",
                            groupSeparator: ".",
                            rightAlign: false,
                            autoGroup: true,
                            repeat: 20,
                            autoUnmask: true,
                            allowMinus: false,
                          },
                          expression:
                            "{\n            alias: 'decimal',\n            groupSeparator: '.',\n            rightAlign: false,\n            autoGroup: true,\n            repeat: 20,\n            autoUnmask: true,\n            allowMinus: false,\n          }",
                        },
                      ],
                      attrs: { disabled: _vm.status == "update" },
                      model: {
                        value: _vm.form.eVoucher.price,
                        callback: function ($$v) {
                          _vm.$set(_vm.form.eVoucher, "price", $$v)
                        },
                        expression: "form.eVoucher.price",
                      },
                    },
                    [_c("template", { slot: "append" }, [_vm._v("đ")])],
                    2
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "Giá trị sử dụng",
                    prop: "eVoucher.discountValue",
                  },
                },
                [
                  _c(
                    "el-input",
                    {
                      directives: [
                        {
                          name: "mask",
                          rawName: "v-mask",
                          value: {
                            alias: "decimal",
                            groupSeparator: ".",
                            rightAlign: false,
                            autoGroup: true,
                            repeat: 20,
                            autoUnmask: true,
                            allowMinus: false,
                          },
                          expression:
                            "{\n            alias: 'decimal',\n            groupSeparator: '.',\n            rightAlign: false,\n            autoGroup: true,\n            repeat: 20,\n            autoUnmask: true,\n            allowMinus: false,\n          }",
                        },
                      ],
                      staticClass: "input-with-select",
                      attrs: { disabled: _vm.status == "update" },
                      model: {
                        value: _vm.form.eVoucher.discountValue,
                        callback: function ($$v) {
                          _vm.$set(_vm.form.eVoucher, "discountValue", $$v)
                        },
                        expression: "form.eVoucher.discountValue",
                      },
                    },
                    [
                      _c("template", { slot: "append" }, [
                        _vm._v(
                          _vm._s(
                            _vm.form.eVoucher.discountType ==
                              _vm.EVoucherDiscountType.Percent
                              ? "%"
                              : "đ"
                          )
                        ),
                      ]),
                    ],
                    2
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "Số lượng bán tối đa",
                    prop: "eVoucher.quantity",
                  },
                },
                [
                  _c("el-input", {
                    directives: [
                      {
                        name: "mask",
                        rawName: "v-mask",
                        value: {
                          alias: "decimal",
                          groupSeparator: ".",
                          rightAlign: false,
                          autoGroup: true,
                          repeat: 20,
                          autoUnmask: true,
                          allowMinus: false,
                          max: 99999999,
                          min: 1,
                        },
                        expression:
                          "{\n            alias: 'decimal',\n            groupSeparator: '.',\n            rightAlign: false,\n            autoGroup: true,\n            repeat: 20,\n            autoUnmask: true,\n            allowMinus: false,\n            max: 99999999,\n            min: 1,\n          }",
                      },
                    ],
                    attrs: { disabled: _vm.status == "update" },
                    model: {
                      value: _vm.form.eVoucher.quantity,
                      callback: function ($$v) {
                        _vm.$set(_vm.form.eVoucher, "quantity", $$v)
                      },
                      expression: "form.eVoucher.quantity",
                    },
                  }),
                  _c("small", { staticStyle: { display: "block" } }, [
                    _vm._v("Tổng số e-voucher có thể bán"),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }