var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "basic-info" } },
    [
      _c(
        "el-card",
        { attrs: { shadow: "always", "body-style": { padding: "20px" } } },
        [
          _c("div", { attrs: { slot: "header" }, slot: "header" }, [
            _c("h2", [_vm._v("Thông tin cơ bản")]),
          ]),
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                model: _vm.form,
                rules: _vm.rules,
                "label-width": "200px",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "Loại mã", prop: "mallVoucher.applyType" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.form.mallVoucher.applyType,
                        callback: function ($$v) {
                          _vm.$set(_vm.form.mallVoucher, "applyType", $$v)
                        },
                        expression: "form.mallVoucher.applyType",
                      },
                    },
                    [
                      _c(
                        "el-radio",
                        { attrs: { label: _vm.MallVoucherApplyType.All } },
                        [_vm._v("Voucher toàn Shop")]
                      ),
                      _c(
                        "el-radio",
                        { attrs: { label: _vm.MallVoucherApplyType.Product } },
                        [_vm._v("Voucher sản phẩm")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "Tên chương trình giảm giá",
                    prop: "mallVoucher.title",
                  },
                },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.form.mallVoucher.title,
                      callback: function ($$v) {
                        _vm.$set(_vm.form.mallVoucher, "title", $$v)
                      },
                      expression: "form.mallVoucher.title",
                    },
                  }),
                  _c("small", { staticStyle: { display: "block" } }, [
                    _vm._v(
                      "Tên mã giảm giá sản phẩm không hiển thị với người mua"
                    ),
                  ]),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "Mã voucher", prop: "mallVoucher.code" } },
                [
                  _c("el-input", {
                    attrs: {
                      type: "text",
                      "show-word-limit": "",
                      maxlength: "5",
                    },
                    model: {
                      value: _vm.form.mallVoucher.code,
                      callback: function ($$v) {
                        _vm.$set(_vm.form.mallVoucher, "code", $$v)
                      },
                      expression: "form.mallVoucher.code",
                    },
                  }),
                  _c("small", { staticStyle: { display: "block" } }, [
                    _vm._v(
                      "Vui lòng chỉ nhập các kí tự chữ cái (A-Z), số (0-9): tối đa 5 kí tự"
                    ),
                  ]),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "Thời gian sử dụng mã",
                    prop: "mallVoucher.start",
                  },
                },
                [
                  _c("el-date-picker", {
                    attrs: {
                      type: "datetimerange",
                      "range-separator": "To",
                      format: "dd/MM/yyyy HH:mm",
                      "value-format": "timestamp",
                      "start-placeholder": "Ngày bắt đầu",
                      "end-placeholder": "Ngày kết thúc",
                      "picker-options": {
                        disabledDate: _vm.handleDisableDate,
                      },
                    },
                    model: {
                      value: _vm.form.date,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "date", $$v)
                      },
                      expression: "form.date",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }