var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        "append-to-body": "",
        title: "Nhập mã pin",
        visible: _vm.dialogVisible,
        width: "500px",
        "close-on-click-modal": false,
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: {
            "label-position": "top",
            model: _vm.formData,
            "label-width": "80px",
          },
          nativeOn: {
            submit: function ($event) {
              $event.preventDefault()
              return _vm.submitPin($event)
            },
          },
        },
        [
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _c(
                "el-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "Mã pin", prop: "pin" } },
                    [
                      _c("v-otp-input", {
                        ref: "otpInput",
                        staticStyle: { "justify-content": "center" },
                        attrs: {
                          "input-classes": "otp-input",
                          separator: "",
                          "input-type": "password",
                          "num-inputs": 6,
                          "sh45ould-auto-focus": true,
                          "is-input-num": true,
                        },
                        on: {
                          "on-change": _vm.handleChangePinCode,
                          "on-complete": _vm.handleChangePinCode,
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "span",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "el-button",
            {
              attrs: { type: "primary", loading: _vm.btnLoading },
              on: { click: _vm.submitPin },
            },
            [_vm._v("Đồng ý")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }