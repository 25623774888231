var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "marketing-item",
      on: {
        click: function ($event) {
          return _vm.onClick()
        },
      },
    },
    [
      _c("div", { staticClass: "marketing-icon" }, [
        _c("img", { attrs: { width: "40", src: _vm.icon, alt: "" } }),
      ]),
      _c("div", { staticClass: "marketing-content" }, [
        _c("label", [_vm._v(_vm._s(_vm.title))]),
        _c("span", [_vm._v(_vm._s(_vm.content))]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }