var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            id: "food-detail-list",
            title: "Chi tiết đơn - " + _vm.formData.code,
            visible: _vm.dialogVisible,
            width: "1200px",
            "append-to-body": "",
            top: "30px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
            close: _vm.handleClose,
          },
        },
        [
          _vm.hash
            ? _c(
                "div",
                [
                  _c("ul", { staticClass: "payment-detail" }, [
                    _c("li", [
                      _c("label", [_vm._v("Khách:")]),
                      _vm.formData.customer
                        ? _c("span", [
                            _vm._v(
                              " " +
                                _vm._s(_vm.formData.customer.name) +
                                " - " +
                                _vm._s(_vm.formData.customer.phone) +
                                " "
                            ),
                          ])
                        : _vm._e(),
                    ]),
                    _c("li", [
                      _c("label", [_vm._v("Thời gian mua:")]),
                      _c("span", [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$formatDateTime(_vm.formData.dateCreated)
                            ) +
                            " "
                        ),
                      ]),
                    ]),
                    _c("li", [
                      _c("label", [_vm._v("Loại sản phẩm:")]),
                      _c("span", [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.MallOrderProductTypeTrans[
                                _vm.formData.productType
                              ]
                            ) +
                            " "
                        ),
                      ]),
                    ]),
                    _vm.formData.productType ==
                    _vm.MallOrderProductType.MallProduct
                      ? _c("li", [
                          _c("label", { attrs: { for: "" } }, [
                            _vm._v("Tổng tiền hàng: "),
                          ]),
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm._f("toThousandFilter")(
                                  _vm.formData.moneyProduct
                                )
                              )
                            ),
                          ]),
                        ])
                      : _vm._e(),
                    _vm.formData.productType ==
                    _vm.MallOrderProductType.MallProduct
                      ? _c("li", [
                          _c("label", { attrs: { for: "" } }, [
                            _vm._v("Phí vận chuyển: "),
                          ]),
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm._f("toThousandFilter")(_vm.formData.shipFee)
                              )
                            ),
                          ]),
                        ])
                      : _vm._e(),
                    _vm.formData.moneyDiscount > 0
                      ? _c("li", [
                          _c("label", { attrs: { for: "" } }, [
                            _vm._v("Tiền khuyến mãi: "),
                          ]),
                          _c("span", [
                            _vm._v(
                              "-" +
                                _vm._s(
                                  _vm._f("toThousandFilter")(
                                    _vm.formData.moneyDiscount
                                  )
                                )
                            ),
                          ]),
                        ])
                      : _vm._e(),
                    _c("li", [
                      _c("label", [_vm._v("Tổng tiền:")]),
                      _c("span", [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$formatNumberVN(_vm.formData.finalPrice)
                            ) +
                            " "
                        ),
                      ]),
                    ]),
                  ]),
                  _c(
                    "el-table",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.loading,
                          expression: "loading",
                        },
                      ],
                      attrs: { data: _vm.dataTable, border: "", fit: "" },
                    },
                    [
                      _c("el-table-column", {
                        attrs: {
                          "class-name": "text-center",
                          type: "index",
                          width: "50",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          sortable: "",
                          prop: "name",
                          label: "Tên hàng",
                          align: "center",
                          width: "270",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (ref) {
                                var row = ref.row
                                return [
                                  _c("div", { staticClass: "info-box" }, [
                                    _c("div", { staticClass: "info" }, [
                                      row.mallProduct
                                        ? _c("span", { staticClass: "name" }, [
                                            _vm._v(
                                              _vm._s(row.mallProduct.name)
                                            ),
                                          ])
                                        : _vm._e(),
                                      row.eVoucherLines.length
                                        ? _c("span", { staticClass: "name" }, [
                                            _vm._v(
                                              _vm._s(
                                                row.eVoucherLines[0].eVoucher
                                                  .name
                                              )
                                            ),
                                          ])
                                        : _vm._e(),
                                    ]),
                                    row.mallProduct
                                      ? _c("img", {
                                          staticClass: "image",
                                          attrs: {
                                            src: _vm.$url.image(
                                              row.mallProduct.thumbnail
                                            ),
                                            alt: "",
                                          },
                                        })
                                      : _vm._e(),
                                    row.eVoucherLines.length
                                      ? _c("img", {
                                          staticClass: "image",
                                          attrs: {
                                            src: _vm.$url.image(
                                              row.eVoucherLines[0].eVoucher
                                                .imageUrl
                                            ),
                                            alt: "",
                                          },
                                        })
                                      : _vm._e(),
                                  ]),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          1401629226
                        ),
                      }),
                      _vm.formData.productType ==
                      _vm.MallOrderProductType.EVoucher
                        ? _c("el-table-column", {
                            attrs: { label: "Mã sản phẩm" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (ref) {
                                    var row = ref.row
                                    return [
                                      _c("span", [_vm._v(_vm._s(row.code))]),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              1027302633
                            ),
                          })
                        : _vm._e(),
                      _vm.formData.productType ==
                      _vm.MallOrderProductType.EVoucher
                        ? _c("el-table-column", {
                            attrs: { label: "Trạng thái sử dụng" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (ref) {
                                    var row = ref.row
                                    return [
                                      !row.isUsed
                                        ? _c(
                                            "el-tag",
                                            {
                                              attrs: {
                                                type: "success",
                                                effect: "dark",
                                              },
                                            },
                                            [_vm._v(" Chưa sử dụng ")]
                                          )
                                        : _c(
                                            "el-tag",
                                            {
                                              attrs: {
                                                type: "danger",
                                                effect: "dark",
                                              },
                                            },
                                            [_vm._v(" Đã sử dụng ")]
                                          ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              3942059603
                            ),
                          })
                        : _vm._e(),
                      _vm.formData.productType ==
                      _vm.MallOrderProductType.EVoucher
                        ? _c("el-table-column", {
                            attrs: { label: "QRCode" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (ref) {
                                    var row = ref.row
                                    return [
                                      _c(
                                        "div",
                                        {
                                          on: {
                                            click: function ($event) {
                                              return _vm.handleViewQrcode(row)
                                            },
                                          },
                                        },
                                        [
                                          _c("QrcodeVue", {
                                            staticClass: "qr-code-vue",
                                            attrs: {
                                              value: row.code,
                                              size: 100,
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              4052178872
                            ),
                          })
                        : _vm._e(),
                      _vm.formData.productType ==
                      _vm.MallOrderProductType.MallProduct
                        ? _c("el-table-column", {
                            attrs: { label: "Phân loại" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (ref) {
                                    var row = ref.row
                                    return [
                                      row.attr1Name
                                        ? _c("div", [
                                            _vm._v(
                                              " " +
                                                _vm._s(row.attr1Name) +
                                                ": " +
                                                _vm._s(row.attr1Value) +
                                                " "
                                            ),
                                          ])
                                        : _vm._e(),
                                      row.attr2Name
                                        ? _c("div", [
                                            _vm._v(
                                              " " +
                                                _vm._s(row.attr2Name) +
                                                ": " +
                                                _vm._s(row.attr2Value) +
                                                " "
                                            ),
                                          ])
                                        : _vm._e(),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              173269745
                            ),
                          })
                        : _vm._e(),
                      _vm.formData.productType ==
                      _vm.MallOrderProductType.MallProduct
                        ? _c("el-table-column", {
                            attrs: {
                              label: "Số lượng",
                              prop: "quantity",
                              align: "right",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (ref) {
                                    var row = ref.row
                                    return [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$formatNumberVN(row.quantity)
                                          ) +
                                          " "
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              1529491801
                            ),
                          })
                        : _vm._e(),
                      _c("el-table-column", {
                        attrs: { label: "Giá", align: "right" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (ref) {
                                var row = ref.row
                                return [
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(_vm.$formatNumberVN(row.price)) +
                                        "đ"
                                    ),
                                  ]),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          1687869230
                        ),
                      }),
                      _vm.formData.productType ==
                      _vm.MallOrderProductType.MallProduct
                        ? _c("el-table-column", {
                            attrs: { align: "right", label: "Thành tiền" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (ref) {
                                    var row = ref.row
                                    return [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$formatNumberVN(
                                              row.quantity * row.price
                                            )
                                          ) + "đ"
                                        ),
                                      ]),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              459198395
                            ),
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c(
                    "span",
                    { attrs: { slot: "footer" }, slot: "footer" },
                    [
                      _c(
                        "el-button",
                        {
                          on: {
                            click: function ($event) {
                              _vm.dialogVisible = false
                            },
                          },
                        },
                        [_vm._v("Đóng")]
                      ),
                    ],
                    1
                  ),
                  _c("el-image", {
                    ref: "imageView",
                    staticStyle: { width: "0", height: "0" },
                    attrs: {
                      src: _vm.selectedImage,
                      "preview-src-list": [_vm.selectedImage],
                    },
                  }),
                  _c("QrCodeDetailModal", { ref: "QrCodeDetailModal" }),
                ],
                1
              )
            : _c(
                "div",
                {
                  staticClass: "app-container",
                  staticStyle: { "max-width": "unset" },
                },
                [
                  _c(
                    "el-row",
                    { attrs: { gutter: 20 } },
                    [
                      this.$store.state.user.info.isHasPinCode
                        ? _c(
                            "el-col",
                            { attrs: { sm: 24, lg: 24 } },
                            [
                              _c(
                                "el-result",
                                {
                                  attrs: {
                                    icon: "warning",
                                    title: "Yêu cầu mã pin",
                                    subTitle:
                                      "Vui lòng nhập mã pin để truy cập trang này",
                                  },
                                },
                                [
                                  _c(
                                    "template",
                                    { slot: "extra" },
                                    [
                                      _c(
                                        "el-button",
                                        {
                                          attrs: {
                                            type: "primary",
                                            size: "medium",
                                          },
                                          on: {
                                            click: _vm.handleShowPinDialog,
                                          },
                                        },
                                        [_vm._v("Nhập mã")]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                2
                              ),
                            ],
                            1
                          )
                        : _c(
                            "el-col",
                            { attrs: { sm: 24, lg: 24 } },
                            [
                              _c(
                                "el-result",
                                {
                                  attrs: {
                                    icon: "warning",
                                    title: "Yêu cầu tạo mã Pin",
                                    subTitle:
                                      "Bạn cần phải tạo mã Pin để truy cập vào trang này",
                                  },
                                },
                                [
                                  _c(
                                    "template",
                                    { slot: "extra" },
                                    [
                                      _c(
                                        "el-button",
                                        {
                                          attrs: {
                                            type: "primary",
                                            size: "medium",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.$router.push({
                                                name: "profile-index",
                                                query: { page: "pin" },
                                              })
                                            },
                                          },
                                        },
                                        [_vm._v("Tạo mã pin")]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                2
                              ),
                            ],
                            1
                          ),
                    ],
                    1
                  ),
                ],
                1
              ),
          _c("pin-dialog", {
            ref: "PinDialog",
            on: { isValidate: _vm.handleValidated },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }