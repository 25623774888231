var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "basic-config" } },
    [
      _c(
        "el-card",
        { attrs: { shadow: "always", "body-style": { padding: "20px" } } },
        [
          _c("div", { attrs: { slot: "header" }, slot: "header" }, [
            _c("h2", [_vm._v("Thiết lập mã giảm giá")]),
          ]),
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                model: _vm.form,
                rules: _vm.rules,
                "label-width": "200px",
              },
            },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "Loại giảm giá|Mức giảm",
                    prop: "mallVoucher.discountValue",
                  },
                },
                [
                  _c(
                    "el-input",
                    {
                      directives: [
                        {
                          name: "mask",
                          rawName: "v-mask",
                          value: {
                            alias: "decimal",
                            groupSeparator: ".",
                            rightAlign: false,
                            autoGroup: true,
                            repeat: 20,
                            autoUnmask: true,
                            allowMinus: false,
                          },
                          expression:
                            "{alias: 'decimal' ,groupSeparator: '.' ,rightAlign: false, autoGroup: true, repeat: 20, autoUnmask: true, allowMinus: false}",
                        },
                      ],
                      staticClass: "input-with-select",
                      model: {
                        value: _vm.form.mallVoucher.discountValue,
                        callback: function ($$v) {
                          _vm.$set(_vm.form.mallVoucher, "discountValue", $$v)
                        },
                        expression: "form.mallVoucher.discountValue",
                      },
                    },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { slot: "prepend" },
                          slot: "prepend",
                          model: {
                            value: _vm.form.mallVoucher.discountType,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.form.mallVoucher,
                                "discountType",
                                $$v
                              )
                            },
                            expression: "form.mallVoucher.discountType",
                          },
                        },
                        [
                          _c("el-option", {
                            attrs: {
                              value: _vm.MallVoucherDiscountType.Price,
                              label: "Theo số tiền",
                            },
                          }),
                          _c("el-option", {
                            attrs: {
                              value: _vm.MallVoucherDiscountType.Percent,
                              label: "Theo phần trăm",
                            },
                          }),
                        ],
                        1
                      ),
                      _c("template", { slot: "append" }, [
                        _vm._v(
                          _vm._s(
                            _vm.form.mallVoucher.discountType ==
                              _vm.MallVoucherDiscountType.Percent
                              ? "%"
                              : "đ"
                          )
                        ),
                      ]),
                    ],
                    2
                  ),
                ],
                1
              ),
              _vm.form.mallVoucher.discountType ==
              _vm.MallVoucherDiscountType.Percent
                ? _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "Mức giảm tối đa",
                        prop: "mallVoucher.isLimitDiscount",
                      },
                    },
                    [
                      _c(
                        "el-radio-group",
                        {
                          model: {
                            value: _vm.form.mallVoucher.isLimitDiscount,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.form.mallVoucher,
                                "isLimitDiscount",
                                $$v
                              )
                            },
                            expression: "form.mallVoucher.isLimitDiscount",
                          },
                        },
                        [
                          _c("el-radio", { attrs: { label: true } }, [
                            _vm._v("Giới hạn"),
                          ]),
                          _c("el-radio", { attrs: { label: false } }, [
                            _vm._v("Không giới hạn"),
                          ]),
                        ],
                        1
                      ),
                      _vm.form.mallVoucher.isLimitDiscount
                        ? _c("el-input", {
                            directives: [
                              {
                                name: "mask",
                                rawName: "v-mask",
                                value: {
                                  alias: "decimal",
                                  groupSeparator: ".",
                                  rightAlign: false,
                                  autoGroup: true,
                                  repeat: 20,
                                  autoUnmask: true,
                                  allowMinus: false,
                                },
                                expression:
                                  "{alias: 'decimal' ,groupSeparator: '.' ,rightAlign: false, autoGroup: true, repeat: 20, autoUnmask: true, allowMinus: false}",
                              },
                            ],
                            model: {
                              value: _vm.form.mallVoucher.discountMaxValue,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.form.mallVoucher,
                                  "discountMaxValue",
                                  $$v
                                )
                              },
                              expression: "form.mallVoucher.discountMaxValue",
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "Giá trị đơn hàng tối thiểu",
                    prop: "mallVoucher.conditionMinPrice",
                  },
                },
                [
                  _c("el-input", {
                    directives: [
                      {
                        name: "mask",
                        rawName: "v-mask",
                        value: {
                          alias: "decimal",
                          groupSeparator: ".",
                          rightAlign: false,
                          autoGroup: true,
                          repeat: 20,
                          autoUnmask: true,
                          allowMinus: false,
                        },
                        expression:
                          "{alias: 'decimal' ,groupSeparator: '.' ,rightAlign: false, autoGroup: true, repeat: 20, autoUnmask: true, allowMinus: false}",
                      },
                    ],
                    model: {
                      value: _vm.form.mallVoucher.conditionMinPrice,
                      callback: function ($$v) {
                        _vm.$set(_vm.form.mallVoucher, "conditionMinPrice", $$v)
                      },
                      expression: "form.mallVoucher.conditionMinPrice",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "Lượt sử dụng tối đa",
                    prop: "mallVoucher.quantity",
                  },
                },
                [
                  _c("el-input", {
                    directives: [
                      {
                        name: "mask",
                        rawName: "v-mask",
                        value: {
                          alias: "decimal",
                          groupSeparator: ".",
                          rightAlign: false,
                          autoGroup: true,
                          repeat: 20,
                          autoUnmask: true,
                          allowMinus: false,
                        },
                        expression:
                          "{alias: 'decimal' ,groupSeparator: '.' ,rightAlign: false, autoGroup: true, repeat: 20, autoUnmask: true, allowMinus: false}",
                      },
                    ],
                    model: {
                      value: _vm.form.mallVoucher.quantity,
                      callback: function ($$v) {
                        _vm.$set(_vm.form.mallVoucher, "quantity", $$v)
                      },
                      expression: "form.mallVoucher.quantity",
                    },
                  }),
                  _c("small", { staticStyle: { display: "block" } }, [
                    _vm._v("Tổng số mã giảm giá có thể sử dụng"),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }