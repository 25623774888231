var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "basic-info" } },
    [
      _c(
        "el-card",
        { attrs: { shadow: "always", "body-style": { padding: "20px" } } },
        [
          _c("div", { attrs: { slot: "header" }, slot: "header" }, [
            _c("h2", [_vm._v("Thông tin cơ bản")]),
          ]),
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                model: _vm.form,
                rules: _vm.rules,
                "label-width": "200px",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "Khung thời gian", prop: "startAt" } },
                [
                  _c(
                    "el-button",
                    {
                      attrs: {
                        plain: "",
                        disabled:
                          _vm.status != "create" &&
                          _vm.statusVoucher !=
                            _vm.MallVoucherStatusQuery.ComingSoon,
                        type: this.form.flashSaleStrategy.startAt
                          ? ""
                          : "primary",
                        icon: "el-icon-date",
                      },
                      on: { click: _vm.handleAddDate },
                    },
                    [
                      _vm._v(
                        _vm._s(
                          this.form.flashSaleStrategy.startAt
                            ? _vm.$formatDateTime(
                                this.form.flashSaleStrategy.startAt
                              ) +
                                " - " +
                                _vm.$formatDateTime(
                                  this.form.flashSaleStrategy.endAt
                                )
                            : "Lựa chọn khung giờ"
                        )
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("DateModal", {
        ref: "DateModal",
        on: { "submit:ok": _vm.handleGetDate },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }