"use strict";

var _interopRequireDefault = require("C:/Users/Admin/Documents/028web_mall/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.addressApi = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
var baseURL = process.env.VUE_APP_BASE_URL_MEDIA;
var addressApi = exports.addressApi = {
  findCity: function findCity(params) {
    return (0, _request.default)({
      baseURL: baseURL,
      url: "/v1/public/city",
      params: params
    });
  },
  findDistrict: function findDistrict(_ref) {
    var cityCode = _ref.cityCode;
    return (0, _request.default)({
      baseURL: baseURL,
      url: "/v1/public/district",
      params: {
        cityCode: cityCode
      }
    });
  },
  findWard: function findWard(_ref2) {
    var districtCode = _ref2.districtCode;
    return (0, _request.default)({
      baseURL: baseURL,
      url: "/v1/public/ward",
      params: {
        parentCode: districtCode
      }
    });
  }
};