import { render, staticRenderFns } from "./VietMap.vue?vue&type=template&id=ac6145f8"
import script from "./VietMap.vue?vue&type=script&lang=ts"
export * from "./VietMap.vue?vue&type=script&lang=ts"
import style0 from "./VietMap.vue?vue&type=style&index=0&id=ac6145f8&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("C:\\Users\\Admin\\Documents\\028web_mall\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('ac6145f8')) {
      api.createRecord('ac6145f8', component.options)
    } else {
      api.reload('ac6145f8', component.options)
    }
    module.hot.accept("./VietMap.vue?vue&type=template&id=ac6145f8", function () {
      api.rerender('ac6145f8', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/Map/components/VietMap.vue"
export default component.exports