var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "upload-control" } },
    [
      _c(
        "el-upload",
        {
          staticStyle: { margin: "0 auto" },
          style: {
            height: _vm.height + "px",
            width: _vm.width + "px",
          },
          attrs: {
            name: _vm.name,
            drag: "",
            "before-upload": _vm.beforeUpload,
            action: _vm.baseUrl + _vm.urlUpload,
            "on-success": _vm.handleSuccess,
            "show-file-list": false,
            headers: { token: _vm.token },
            "on-progress": _vm.onProgress,
            "on-error": _vm.onError,
            disabled: _vm.disabled,
          },
        },
        [
          _vm.isProgressing
            ? _c("el-progress", {
                attrs: { type: "circle", percentage: _vm.percentUpload },
              })
            : _vm._e(),
          !_vm.isProgressing
            ? [
                _vm.pathImage
                  ? _c("img", {
                      staticClass: "image",
                      staticStyle: { "object-fit": "contain" },
                      style:
                        "width:" + _vm.width + "px;height:" + _vm.height + "px",
                      attrs: { src: _vm.baseUrlImage + _vm.pathImage, alt: "" },
                    })
                  : [
                      _c("i", { staticClass: "el-icon-upload" }),
                      _c(
                        "div",
                        {
                          staticClass: "el-upload__text",
                          staticStyle: { "font-size": "11px" },
                        },
                        [
                          _vm._v(" Kéo vào hoặc "),
                          _c("em", [_vm._v("click để upload")]),
                        ]
                      ),
                    ],
              ]
            : _vm._e(),
        ],
        2
      ),
      _vm.pathImage
        ? _c(
            "div",
            {
              staticStyle: {
                "align-items": "center",
                display: "flex",
                "flex-direction": "column",
                "margin-top": "12px",
              },
            },
            [
              _vm.pathImage
                ? _c("el-button", {
                    attrs: {
                      icon: "el-icon-view",
                      circle: "",
                      type: "primary",
                      size: "mini",
                    },
                    on: { click: _vm.handlePreviewImage },
                  })
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _c("el-image", {
        ref: "imagePreview",
        staticClass: "image",
        style: "height:0",
        attrs: {
          alt: "",
          "z-index": 99999,
          fit: "contain",
          src: _vm.baseUrlImage + _vm.pathImage,
          "preview-src-list": [_vm.baseUrlImage + _vm.pathImage],
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }