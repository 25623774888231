var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            id: "food-detail-list",
            title: "Ghi chú delay - " + (_vm.formData.code || ""),
            visible: _vm.dialogVisible,
            width: "600px",
            "append-to-body": "",
            top: "30px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
            close: _vm.handleClose,
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                "label-position": "top",
                model: _vm.formData,
                rules: _vm.rules,
                "label-width": "80px",
                inline: false,
                size: "normal",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "Ghi chú", prop: "delayNote" } },
                [
                  _c("el-input", {
                    attrs: { type: "textarea", rows: 3 },
                    model: {
                      value: _vm.formData.delayNote,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "delayNote", $$v)
                      },
                      expression: "formData.delayNote",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { size: "small", type: "primary" },
                      on: { click: _vm.onSubmit },
                    },
                    [_vm._v("Lưu")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }