"use strict";

var _interopRequireDefault = require("C:/Users/Admin/Documents/028web_mall/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.eVoucherApi = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
var eVoucherApi = exports.eVoucherApi = {
  findAll: function findAll(params) {
    return (0, _request.default)({
      url: "/eVoucher",
      params: params
    });
  },
  findOne: function findOne(id) {
    return (0, _request.default)({
      url: "/eVoucher/".concat(id)
    });
  },
  create: function create(data) {
    return (0, _request.default)({
      url: "/eVoucher",
      data: data,
      method: 'post'
    });
  },
  update: function update(id, data) {
    return (0, _request.default)({
      url: "/eVoucher/".concat(id),
      method: 'patch',
      data: data
    });
  },
  delete: function _delete(id) {
    return (0, _request.default)({
      url: "/eVoucher/".concat(id),
      method: 'delete'
    });
  },
  check: function check(data) {
    return (0, _request.default)({
      url: "/eVoucher/check",
      data: data,
      method: 'post'
    });
  },
  use: function use(data) {
    return (0, _request.default)({
      url: "/eVoucher/use",
      data: data,
      method: 'post'
    });
  }
};