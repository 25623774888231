var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c("BasicInfo", {
        ref: "BasicInfo",
        attrs: {
          form: _vm.form,
          statusVoucher: _vm.statusVoucher,
          status: _vm.status,
        },
      }),
      _c("ProductInfo", {
        ref: "ProductInfo",
        staticStyle: { "margin-top": "12px" },
        attrs: {
          form: _vm.form,
          statusVoucher: _vm.statusVoucher,
          status: _vm.status,
        },
      }),
      _c(
        "div",
        { staticClass: "footer-create-product" },
        [
          _c("el-button", { on: { click: _vm.onCancel } }, [_vm._v("Huỷ")]),
          _vm.status == "create" ||
          _vm.statusVoucher == _vm.MallVoucherStatusQuery.ComingSoon
            ? _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.loading },
                  on: {
                    click: function ($event) {
                      _vm.status == "create"
                        ? _vm.createData()
                        : _vm.updateData()
                    },
                  },
                },
                [
                  _vm._v(
                    _vm._s(_vm.status == "create" ? "Tạo mới" : "Cập nhật")
                  ),
                ]
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }