"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.MallType = exports.MallStatus = exports.MallProfileStatus = exports.MallModule = void 0;
var MallType;
(function (MallType) {
  MallType["Individual"] = "INDIVIDUAL";
  MallType["Company"] = "COMPANY"; //Công ty/Hộ cá thể
})(MallType || (exports.MallType = MallType = {}));
var MallStatus;
(function (MallStatus) {
  MallStatus["Online"] = "ONLINE";
  MallStatus["Offline"] = "OFFLINE";
})(MallStatus || (exports.MallStatus = MallStatus = {}));
var MallProfileStatus;
(function (MallProfileStatus) {
  MallProfileStatus["Pending"] = "PENDING";
  MallProfileStatus["Approve"] = "APPROVE";
  MallProfileStatus["Cancel"] = "CANCEL";
})(MallProfileStatus || (exports.MallProfileStatus = MallProfileStatus = {}));
var MallModule;
(function (MallModule) {
  MallModule["Mall"] = "MALL";
  MallModule["SecondHand"] = "SECOND_HAND";
})(MallModule || (exports.MallModule = MallModule = {}));