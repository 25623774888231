"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.mallReturnOrderApi = void 0;
var _request = require("@/utils/request");
var mallReturnOrderApi = exports.mallReturnOrderApi = {
  findAll: function findAll(params) {
    return (0, _request.request)({
      url: "/mallReturnOrder",
      params: params
    });
  },
  summary: function summary(params) {
    return (0, _request.request)({
      url: "/mallReturnOrder/summary/status",
      params: params
    });
  },
  confirm: function confirm(id, data) {
    return (0, _request.request)({
      url: "/mallReturnOrder/".concat(id, "/confirm"),
      method: "patch",
      data: data
    });
  },
  createShip: function createShip(id) {
    return (0, _request.request)({
      url: "/mallReturnOrder/".concat(id, "/ship"),
      method: "post"
    });
  },
  uploadMedia: function uploadMedia(data) {
    return (0, _request.request)({
      url: "/mallReturnOrder/media",
      method: "post",
      data: data
    });
  },
  confirmReceiveReturn: function confirmReceiveReturn(id) {
    return (0, _request.request)({
      url: "/mallReturnOrder/".concat(id, "/confirm/receiveReturn"),
      method: "patch"
    });
  },
  complain: function complain(id, data) {
    return (0, _request.request)({
      url: "/mallReturnOrder/".concat(id, "/complain"),
      method: "patch",
      data: data
    });
  }
};